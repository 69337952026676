import React, { useMemo, useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import TabGridAndFormLayout from "../../../Components/ProForms/ProFormsLayout/TabGridAndFormLayout";
import ProGrid from "../../../Components/ProGrid/v2";
import { convertFileToBase64Model, downloadFile, prepareDefaultValues, prepareInitialConfig } from "../../../../utils/formHelper";
import useColumn from "../../../../utils/customHooks/useColumns";
import NameCell from "../../../Components/ProGrid/components/NameCell";
import DeleteCell from "../../../Components/ProGrid/components/DeleteCell";
import DownloadCell from "../../../Components/ProGrid/components/DownloadCell";
import usePageState from "../../../../utils/customHooks/usePageState";
import { errorToast, successToast } from "../../../../utils/toastHelper";
import { useForm } from "react-hook-form";
import { STAUTES } from "../../../../utils/constant";
import FileUploadField from "../../../Components/Inputs/FileUploadField";
import ProSelectField from "../../../Components/Inputs/SelectField";
import { postApi, getApi, deleteApi, putApi } from "../../../../utils/services";
import { defaultDropdownConfig } from "../../../../utils/dropdownHelper";
import {
  GET_ALL_RAP_DOCUMENTS,
  DELETE_RAP_DOCUMENT,
  GET_ALL_DOCUMENTS_TYPES,
  ADD_RAP_DOCUMENT,
  UPDATE_RAP_DOCUMENT,
  GET_RAP_DOCUMENT_BY_ID
} from "../../../../utils/services/apiPath";

const RAPDocumentsFormConfing = {
  documentTypeId: {
    label: "Document Type",
    perKey: "SelectDocument",
    rules: { required: "Required!" }
  },
  documents: {
    label: "Select Documents",
    rules: { required: "Documents is required!" }
  }
};

export default function RAPDocuments() {
  const { id } = useParams();
  const [showGrid, setShowGrid] = useState(true);
  const [formConfig, setFormConfig] = useState(prepareInitialConfig(RAPDocumentsFormConfing));
  const { handleSubmit, control, setValue } = useForm({ defaultValues: prepareDefaultValues(RAPDocumentsFormConfing) });
  const [documentTypesData, setDocumentTypesData] = useState({});
  const [actionData, setActionData] = useState({});
  const [formData, setFormData] = useState();
  const [isAddMode, setIsAddMode] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const {
    currentPage,
    pageSize,
    data: rapDocumentsData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();

  const columns = useColumn(
    [
      {
        field: "documentsName",
        headerName: "Documents Name",
        renderCell: params => <NameCell params={params} onClick={onDocumentClick} />
      },
      {
        field: "rapDocumentsType",
        headerName: "Documents Type"
      },
      {
        field: "download",
        headerName: "Download",
        renderCell: params => <DownloadCell params={params} onOpen={() => downloadFile(params.row.mediaResponse.path)} />
      },
      {
        field: "wpsActivitiesGuild",
        headerName: "Actions",
        isDeleteColumn: true,
        disableExport: true,
        width: 80,
        renderCell: params => <DeleteCell params={params} onDelete={onDelete} />
      }
    ],
    true
  );

  const isEditModeOn = useMemo(() => Object.values(formConfig).some(({ mode }) => mode === "edit"), [formConfig]);

  const onEdit = () => {
    setFormConfig(prev => Object.keys(prev).reduce((acc, key) => ({ ...acc, [key]: { ...prev[key], mode: "edit" } }), {}));
  };
  const handleReadValueclick = name => {
    setFormConfig(prev => ({ ...prev, [name]: { ...prev[name], mode: "edit" } }));
  };

  const resetForm = () => {
    setFormData({});
    setShowGrid(true);
    setFormConfig(prev => Object.keys(prev).reduce((acc, key) => ({ ...acc, [key]: { ...prev[key], mode: "read" } }), {}));
  };

  const onCancel = () => {
    resetForm();
  };

  const onDocumentClick = useCallback(async params => {
    setShowGrid(false);
    setActionData(params.row);
    const res = await getApi(GET_RAP_DOCUMENT_BY_ID + params.row.rapDocumentsGuid);
    const data = {
      documents: res.documentsName,
      documentTypeId: res.rapDocumentsTypeGuid
    };
    setFormData(data);
    Object.keys(formConfig).forEach(field => setValue(field, data[field]));
  });

  const addButtonAction = () => {
    Object.keys(formConfig).forEach(field => setValue(field, null));
    onEdit();
    setShowGrid(false);
    setIsAddMode(true);
  };

  const getAllDocumentType = async () => {
    const res = await getApi(GET_ALL_DOCUMENTS_TYPES, defaultDropdownConfig);
    setDocumentTypesData(res?.map(item => ({ label: item.label, value: item.value })));
  };

  const onSave = async data => {
    if (isSaving) return; 
    setIsSaving(true);
    const base64ProfilePicture = await convertFileToBase64Model(data.documents);
    const { rapDocumentsGuid } = actionData;
    const payload = {
      rapDocumentsTypeGuid: data?.documentTypeId,
      rapInfoGuid: id,
      rapDocumentsType: data?.documentTypeId,
      logoMediaModel: base64ProfilePicture ?? null,
      rapDocumentsGuid: rapDocumentsGuid ?? null
    };
    const methodApi = rapDocumentsGuid ? putApi : postApi;
    const { error } = await methodApi(rapDocumentsGuid ? UPDATE_RAP_DOCUMENT : ADD_RAP_DOCUMENT, payload);
    if (error) return errorToast(error);
    successToast(`Partners Documents ${rapDocumentsGuid ? "Updated" : "Addeed"}successfully.`);
    getDocumentList();
    setIsSaving(false);
    resetForm();
  };

  const onDelete = async id => {
    const { error } = await deleteApi(`${DELETE_RAP_DOCUMENT}?rapDocumentId=${id}`);
    if (error) return errorToast(error);
    successToast("RAP Document deleted successfully");
    getDocumentList();
  };

  const getDocumentList = useCallback(async () => {
    const payload = {
      pageIndex: searchString ? 1 : currentPage + 1,
      pageSize: pageSize,
      searchString: searchString,
      orderByDescending: true,
      ...sortModel
    };
    const { data, totalRecords, error } = await postApi(`${GET_ALL_RAP_DOCUMENTS}?rapInfoId=${id}`, payload);
    if (error) return errorToast(error);

    setPageState(prevPageInfo => ({
      ...prevPageInfo,
      data:
        data?.map((item, index) => {
          return { ...item, id: index };
        }) || [],
      rowCount: totalRecords
    }));
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus]);

  useEffect(() => {
    getDocumentList();
    getAllDocumentType();
  }, [getDocumentList]);

  const defaultFormProps = { control, formValues: formData, isAddMode, handleReadValueclick };
  return (
    <TabGridAndFormLayout
      showGrid={showGrid}
      title={`${actionData?.rapDocumentsGuid ? "Edit" : "Add"} Document`}
      backAction={() => resetForm()}
      beingEdited={isEditModeOn}
      onEdit={onEdit}
      canView={true}
      backLabel={"Go Back to Documents"}
      hideEdit={false}
      onSave={handleSubmit(onSave)}
      onCancel={onCancel}
      isSaving={isSaving}
    >
      {showGrid && (
        <ProGrid
          title="Documents"
          loading={status === STAUTES.LOADING}
          options={{
            getRowId: row => row.rapDocumentsGuid,
            rowCount: rowCount,
            paginationMode: "server",
            paginationModel: { pageSize: pageSize, page: currentPage },
            onPaginationModelChange: setPagination,
            sortingMode: "server",
            onSortModelChange: setSortModel
          }}
          columns={columns}
          rows={rapDocumentsData}
          searchMode={!!searchString}
          searchModel={{ defaultValue: searchString, handleDebounce: setSearchString }}
          addButtonLabel="+ Add Document"
          hideExport={false}
          hideAddButton={false}
          addButtonAction={addButtonAction}
        />
      )}
      {!showGrid && (
        <>
          <FileUploadField {...defaultFormProps} {...formConfig.documents} />
          <ProSelectField {...defaultFormProps} {...formConfig.documentTypeId} options={documentTypesData} />
        </>
      )}
    </TabGridAndFormLayout>
  );
}
