import React, { useCallback, useEffect } from "react";
import Layout from "../../Components/Layout";
import ProGrid from "../../Components/ProGrid/v2";
import useColumn from "../../../utils/customHooks/useColumns";
import DeleteCell from "../../Components/ProGrid/components/DeleteCell";
import NameCell from "../../Components/ProGrid/components/NameCell";
import { useNavigate } from "react-router-dom";
import { GET_SPONSOR_INFOS, DELETE_SPONSOR_INFO } from "../../../utils/services/apiPath";
import usePageState from "../../../utils/customHooks/usePageState";
import { STAUTES } from "../../../utils/constant";
import { postApi, deleteApi } from "../../../utils/services";
import { errorToast, successToast } from "../../../utils/toastHelper";

const getRedirectUrl = params => {
  return `/sponsor-info/${params.id}`;
};

export default function SponsorsInfoList() {
  const {
    currentPage,
    pageSize,
    data: sponsorInfoListData,
    rowCount,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();
  const navigate = useNavigate();
  const columns = useColumn([
    {
      field: "companyName",
      headerName: "Company Name",
      renderCell: params => <NameCell params={params} getRedirectUrl={getRedirectUrl} />
    },
    {
      field: "contactPhoneNumber",
      headerName: "Contact"
    },
    {
      field: "email",
      headerName: "Email"
    },
    {
      field: "sponsorInformationId",
      headerName: "Actions",
      isDeleteColumn: true,
      disableExport: true,
      width: 80,
      renderCell: params => <DeleteCell params={params} onDelete={onDelete} />
    }
  ]);

  const getSponsorInfoList = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const payload = {
      pageIndex: searchString ? 1 : currentPage + 1,
      pageSize: pageSize,
      searchString: searchString,
      orderByAscending: false,
      orderCol: "companyName",
      ...sortModel
    };
    const { data, totalRecords, error } = await postApi(GET_SPONSOR_INFOS, payload);
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    setPageState(prevPageInfo => ({ ...prevPageInfo, data: data || [], rowCount: totalRecords }));
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus]);

  const onDelete = async id => {
    const { error } = await deleteApi(DELETE_SPONSOR_INFO, { params: { sponsorInformationId: id } });
    if (error) return errorToast(error);
    successToast("Sponsor's Info deleted successfully");
    getSponsorInfoList();
  };

  useEffect(() => {
    getSponsorInfoList();
  }, [getSponsorInfoList]);

  const addButtonAction = () => {
    navigate(`/sponsor-info/add`);
  };

  return (
    <Layout>
      <ProGrid
        title="Sponsor Information"
        columns={columns}
        rows={sponsorInfoListData}
        addButtonLabel="+ Add Sponsor Info"
        addButtonAction={addButtonAction}
        searchMode={!!searchString}
        searchModel={{ defaultValue: searchString, handleDebounce: setSearchString }}
        options={{
          getRowId: row => row.sponsorInformationGuid,
          rowCount: rowCount,
          paginationMode: "server",
          paginationModel: { pageSize: pageSize, page: currentPage },
          onPaginationModelChange: setPagination,
          sortingMode: "server",
          onSortModelChange: setSortModel
        }}
      />
    </Layout>
  );
}
