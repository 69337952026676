import React, { useMemo, useState, useCallback, useEffect } from "react";
import TabGridAndFormLayout from "../../../Components/ProForms/ProFormsLayout/TabGridAndFormLayout";
import ProGrid from "../../../Components/ProGrid/v2";
import { changeMode, changeModeForField, fieldTruePermission, prepareDefaultValues, prepareInitialConfig } from "../../../../utils/formHelper";
import useColumn from "../../../../utils/customHooks/useColumns";
import usePageState from "../../../../utils/customHooks/usePageState";
import { useForm } from "react-hook-form";
import { STAUTES } from "../../../../utils/constant";
import ProTextInput from "../../../Components/Inputs/TextField";
import { ADD_RAP_RTI, DELETE_RAP_RTI, GET_ALL_RAP_RTI, UPDATE_RAP_RTI } from "../../../../utils/services/apiPath";
import { deleteApi, postApi, putApi } from "../../../../utils/services";
import { useParams } from "react-router-dom";
import NameCell from "../../../Components/ProGrid/components/NameCell";
import DeleteCell from "../../../Components/ProGrid/components/DeleteCell";
import { errorToast, successToast } from "../../../../utils/toastHelper";

const RTIDescriptionFormConfing = {
  title: {
    label: "Title",
    rules: { required: "Title is required!" }
  },
  description: {
    label: "Description",
    fieldProps: { multiline: true, rows: 4 },
    rules: { required: "Description is required!" }
  },
  approximateHours: {
    label: "Approximate Hours",
    rules: { required: "Approximate Hours is required!" }
  },
  creditHours: {
    label: "Credit Hours",
    rules: { required: "Credit Hours is required!" }
  }
};
export default function RelatedInstructionDescriptions() {
  const { id } = useParams();
  const [showGrid, setShowGrid] = useState(true);
  const [formConfig, setFormConfig] = useState(prepareInitialConfig(RTIDescriptionFormConfing));
  const [rtiDescriptionData, setRtiDescriptionData] = useState({});
  const [actionData, setActionData] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const {
    currentPage,
    pageSize,
    data: rtiData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();
  const columns = useColumn(
    [
      {
        field: "rtiTitle",
        headerName: "Title",
        renderCell: params => <NameCell params={params} onClick={onUserClick} />
      },
      {
        field: "approximateHours",
        headerName: "Approximate Hours"
      },
      {
        field: "creditHours",
        headerName: "Credit Hours"
      },
      {
        field: "rtiDescriptionGuild",
        headerName: "Actions",
        isDeleteColumn: true,
        disableExport: true,
        width: 80,
        renderCell: params => <DeleteCell params={params} onDelete={onDelete} title={"Delete RTI Description"} />
      }
    ],
    true
  );
  const { handleSubmit, control, setValue } = useForm({ defaultValues: prepareDefaultValues(RTIDescriptionFormConfing) });
  const isEditModeOn = useMemo(() => Object.values(formConfig).some(({ mode }) => mode === "edit"), [formConfig]);

  const onEdit = () => {
    setFormConfig(changeMode("edit"));
  };
  const handleReadValueclick = name => setFormConfig(changeModeForField(name, "edit"));

  const resetForm = () => {
    setActionData({});
    setShowGrid(true);
    setFormConfig(changeMode("read"));
  };

  const onCancel = () => {
    resetForm();
    setShowGrid(true);
  };

  const onUserClick = params => {
    setShowGrid(false);
    setActionData(params.row);
    getClientUserData(params.row);
  };

  const addButtonAction = () => {
    Object.keys(formConfig).forEach(field => setValue(field, null));
    onEdit();
    setShowGrid(false);
  };

  const onSave = async data => {
    if (isSaving) return;
    setIsSaving(true);
    const payload = {
      rapInfoGuid: id,
      rtiDescription: data?.description,
      rtiTitle: data?.title,
      approximateHours: parseInt(data?.approximateHours),
      creditHours: parseInt(data?.creditHours),
      raprtiGuid: actionData?.raprtiGuid ?? null
    };
    const methodApi = actionData?.raprtiGuid ? putApi : postApi;
    const { error } = await methodApi(actionData?.raprtiGuid ? UPDATE_RAP_RTI : ADD_RAP_RTI, payload);
    if (error) return errorToast(error);
    getRTIDescription();
    setIsSaving(false);
    resetForm();
    successToast(`RTI Description ${actionData?.raprtiGuid ? "updated" : "added"} successfully.`);
  };

  const onDelete = async id => {
    const { error } = await deleteApi(`${DELETE_RAP_RTI}?rapRtiId=${id}`);
    if (error) return errorToast(error);
    getRTIDescription();
    setShowGrid(true);
    successToast("RTI Description deleted successfully.");
  };

  const getClientUserData = updateData => {
    const data = {
      description: updateData?.rtiDescription,
      title: updateData?.rtiTitle,
      approximateHours: updateData?.approximateHours,
      creditHours: updateData?.creditHours
    };
    setRtiDescriptionData(data);
    Object.keys(formConfig).forEach(field => setValue(field, data[field]));
  };

  const getRTIDescription = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const payload = {
      pageIndex: searchString ? 1 : currentPage + 1,
      pageSize: pageSize,
      searchString: searchString,
      orderByAscending: true,
      ...sortModel
    };

    const { data, totalRecords, error } = await postApi(GET_ALL_RAP_RTI, payload, { params: { rapInfoId: id } });
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    setPageState(prevPageInfo => ({ ...prevPageInfo, data: data || [], rowCount: totalRecords }));
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus]);

  useEffect(() => {
    getRTIDescription();
  }, [getRTIDescription]);

  const defaultFormProps = { control, formValues: rtiDescriptionData, handleReadValueclick };
  return (
    <TabGridAndFormLayout
      showGrid={showGrid}
      title={`${actionData?.raprtiGuid ? "Edit" : "Add"} Related Instruction Descriptions`}
      backAction={() => resetForm()}
      beingEdited={isEditModeOn}
      onEdit={onEdit}
      backLabel={"Go Back to Related Instruction Descriptions"}
      canView={true}
      hideEdit={false}
      onSave={handleSubmit(onSave)}
      onCancel={onCancel}
      isSaving={isSaving}
    >
      {showGrid && (
        <ProGrid
          title="Related Instruction Descriptions"
          loading={status === STAUTES.LOADING}
          options={{
            getRowId: row => row.raprtiGuid,
            rowCount: rowCount,
            paginationMode: "server",
            paginationModel: { pageSize: pageSize, page: currentPage },
            onPaginationModelChange: setPagination,
            sortingMode: "server",
            onSortModelChange: setSortModel
          }}
          columns={columns}
          rows={rtiData}
          searchMode={!!searchString}
          searchModel={{ defaultValue: searchString, handleDebounce: setSearchString }}
          addButtonLabel="+ Add RTI Description"
          hideExport={false}
          hideAddButton={false}
          addButtonAction={addButtonAction}
        />
      )}
      {!showGrid && (
        <>
          <ProTextInput {...defaultFormProps} {...formConfig.title} permission={fieldTruePermission} />
          <ProTextInput {...defaultFormProps} {...formConfig.approximateHours} type="number" permission={fieldTruePermission} />
          <ProTextInput {...defaultFormProps} {...formConfig.creditHours} type="number" permission={fieldTruePermission} />
          <ProTextInput {...defaultFormProps} {...formConfig.description} permission={fieldTruePermission} />
        </>
      )}
    </TabGridAndFormLayout>
  );
}
