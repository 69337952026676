import React, { useEffect, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DetailPageLayout from "../../../Components/Layout/DetailPageLayout";
import Layout from "../../../Components/Layout";
import ProfileIcon from "../../../Assets/IconComponents/ProfileIcon";
import { errorToast } from "../../../../utils/toastHelper";
import ContactIcon from "../../../Assets/IconComponents/ContactIcon";
import ClientProfile from "./ClientProfile";
import ClientUsers from "./ClientUsers";
import ClientApi from "./ClientApi";
import { getApi } from "../../../../utils/services";
import { GET_CLIENT } from "../../../../utils/services/apiPath";

export default function ClientDetail() {
  const { tab, id } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("");
  const isAddMode = useMemo(() => id === "add", [id]);
  const [clientSubTitle, setClientSubTitle] = useState("");
  const tabs = isAddMode
    ? [
        {
          to: "/profile",
          title: "Profile",
          key: "profile",
          isActive: false,
          hasArrow: false,
          icon: ProfileIcon
        }
      ]
    : [
        {
          to: "/profile",
          title: "Profile",
          key: "profile",
          isActive: false,
          hasArrow: false,
          icon: ProfileIcon
        },
        {
          to: "/users",
          title: "Users",
          key: "users",
          isActive: false,
          hasArrow: false,
          icon: ContactIcon
        },
        {
          to: "/api",
          title: "Api",
          key: "api",
          isActive: false,
          hasArrow: false,
          icon: ContactIcon
        }
      ];

  const backAction = () => {
    navigate("/clients");
  };

  const handleTabClick = tab => {
    navigate(`/clients/${id}${tab.to}`);
  };

  const getClientProfileData = async id => {
    const { error, ...rest } = await getApi(`${GET_CLIENT}${id}`);
    if (error) return errorToast(error);
    setClientSubTitle(rest.companyName);
  };

  useEffect(() => {
    if (!isAddMode) getClientProfileData(id);
  }, [id]);

  useEffect(() => {
    const selectedTab = tabs.find(t => t.key === tab?.toLowerCase())?.title;
    setActiveTab(selectedTab || "Profile");
  }, [tab]);

  return (
    <Layout>
      {activeTab && (
        <DetailPageLayout
          backAction={backAction}
          tabs={tabs}
          title={isAddMode ? "ADD CLIENT" : "CLIENT INFORMATION"}
          subTitle={isAddMode ? "" : clientSubTitle}
          backLabel="Go back to Clients"
          activeTab={activeTab}
          handleTabClick={handleTabClick}
        >
          {activeTab === "Profile" && <ClientProfile />}
          {activeTab === "Users" && <ClientUsers />}
          {activeTab === "Api" && <ClientApi />}
        </DetailPageLayout>
      )}
    </Layout>
  );
}
