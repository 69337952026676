import React, { useEffect, useState } from "react";
import ProPreLoginLayout from "../../Components/ProPreLoginLayout";
import ProTextInput from "../../Components/Inputs/TextField";
import ProButton from "../../Components/ProButton";
import { NavLink, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { handleLogin, loginSelector } from "../../store/features/auth/authSlice";
import { STAUTES } from "../../../utils/constant";
import { CircularProgress, FormHelperText, IconButton, InputAdornment } from "@mui/material";
import "./proLogin.scss";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { fieldTruePermission } from "../../../utils/formHelper";

export const loginInfoConfig = {
  username: {
    name: "username",
    label: "Username",
    rules: { required: "Username is required!" }
  },
  password: {
    name: "password",
    rules: { required: "Password is required!" },
    label: "Password",
    type: "password"
  }
};

const Login = () => {
  const navigate = useNavigate();
  const { status, isAuthenticated } = useSelector(loginSelector);
  const [helperText, setHelperText] = useState("");
  const { control, handleSubmit } = useForm({});
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();

  const onLogin = async data => {
    dispatch(
      handleLogin(data, error => {
        setHelperText(error);
      })
    );
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  useEffect(() => {
    if (isAuthenticated) {
      if (location.state?.from) navigate(location.state.from.pathname + location.state.from.search, { replace: true });
      else navigate("/clients");
    }
  }, [isAuthenticated, navigate]);
  const loading = status === STAUTES.LOADING;

  return (
    <ProPreLoginLayout heading="Login">
      <div className="proLogin">
        <form className="proLogin_form" onSubmit={handleSubmit(onLogin)}>
          <div className="proLogin_form_input">
            <ProTextInput {...loginInfoConfig.username} permission={fieldTruePermission} control={control} />
          </div>
          <div className="proLogin_form_input">
            <ProTextInput
              {...loginInfoConfig.password}
              control={control}
              permission={fieldTruePermission}
              fieldProps={{
                type: showPassword ? "text" : "password",
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }
              }}
            />
          </div>
          {helperText && (
            <FormHelperText error className="proLogin_form_error">
              {helperText}
            </FormHelperText>
          )}
          <div className="proLogin_form_button">
            <ProButton
              type="submit"
              style={{ width: "100%" }}
              disabled={loading}
              {...(loading ? { endIcon: <CircularProgress color="inherit" /> } : {})}
            >
              Login
            </ProButton>
          </div>
          <div className="proLogin_form_link">
            <NavLink to="/forgot-password">Forgot password?</NavLink>
          </div>
        </form>
      </div>
    </ProPreLoginLayout>
  );
};

export default Login;
