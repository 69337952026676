import React, { useCallback, useEffect, useState } from "react";
import usePageState from "../../../../utils/customHooks/usePageState";
import useColumn from "../../../../utils/customHooks/useColumns";
import TabGridAndFormLayout from "../../../Components/ProForms/ProFormsLayout/TabGridAndFormLayout";
import ProGrid from "../../../Components/ProGrid/v2";
import {
  PANDADOC_API_KEY,
  ETA671_TEMPLATE_UUID,
  APPENDIXB_TEMPLATE_UUID,
  PROSCORE_ADMIN_EMAIL,
  PROSCORE_ADMIN_FIRST_NAME,
  PROSCORE_ADMIN_LAST_NAME,
  STAUTES,
  PANDADOC_API_URI
} from "../../../../utils/constant";
import { dateColumnDef } from "../../../Components/ProGrid/components/FormatCell";
import ProButton from "../../../Components/ProButton";
import {
  ADD_EMPLOYEE_FORMS,
  GET_ALL_APPRENTICE_APENDIXBRECORDS,
  GET_ALL_APPRENTICE_DOCUMENT_S3,
  GET_ALL_APPRENTICE_ETAFORM671,
  GET_ALL_APPRENTICE_MANAGEMENT_DOCUMENTS,
  GET_ALL_APPRENTICE_MANAGEMENT_EMPLOYEE_BY_ID,
  GET_FORM_STATUS,
  SEND_NOTIFICATION_FOR_DOCUMENT_SIGNATURE
} from "../../../../utils/services/apiPath";
import { getApi, postApi } from "../../../../utils/services";
import { useParams } from "react-router-dom";
import { errorToast } from "../../../../utils/toastHelper";
import { Box, Dialog, Grid, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DrawIcon from "@mui/icons-material/Draw";
import ReviewDocument from "./ReviewDocument";
import SignDocument from "./SignDocument";
import dayjs from "dayjs";
import CloseIcon from "@mui/icons-material/Close";
import { decryptSSN } from "../../../../utils/crypto";
import { downloadFile } from "../../../../utils/formHelper";

export default function ApprenticeDocument() {
  const { id } = useParams();
  const {
    currentPage,
    pageSize,
    data: documentData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();
  const [proscoreDocumentOpen, setProscoreDocumentOpen] = useState(false);
  const [sessionId, setSessionId] = useState("");
  const [documentId, setDocumentId] = useState("");
  const [formName, setFormName] = useState("");
  const [aPIKey, setAPIKey] = useState("");
  const [apprenticeEmail, setApprenticeEmail] = useState("");
  const [apprenticeFirstName, setApprenticeFirstName] = useState("");
  const [apprenticeLastName, setApprenticeLastName] = useState("");
  const [sendDocumentStatus, setSendDocumentStatus] = useState(false);
  const [isDocumentSent, setIsDocumentSent] = useState(false);
  const [openSignDocument, setOpenSignDocument] = useState(false);
  const [textButton, setTextButton] = useState("Create ETA671 Form");
  const [textButton1, setTextButton1] = useState("Create Appendix B document");
  const [modalMessage, setModalMessage] = useState("");
  const [openMessage, setOpenMessage] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [open, setOpen] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(false);

  const columns = useColumn(
    [
      {
        field: "formName",
        headerName: "Form Name"
      },
      dateColumnDef({
        field: "createdDate",
        headerName: "Created Date"
      }),
      {
        field: "status",
        headerName: "E-signed Status"
      },
      {
        field: "employeeFormsGuid",
        headerName: "Actions",
        isDeleteColumn: true,
        disableExport: true,
        width: 80,
        renderCell: params => {
          return (
            <Box>
              {params.row.status === "Completed" ? (
                <Tooltip title={"Download Document"} arrow>
                  <IconButton color="primary" onClick={() => handleDownloadDocument(params.row.documentsKey)}>
                    <FileDownloadIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title={"Sign Document"} arrow>
                  <IconButton color="primary" onClick={() => handleSignDocument(params.row.secondSignerSharedLink)}>
                    <DrawIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          );
        }
      }
    ],
    true
  );

  const handleDownloadDocument = async documentsKey => {
    const response = await getApi(`${GET_ALL_APPRENTICE_DOCUMENT_S3}?documentsKey=${documentsKey}`);
    downloadFile(response);
  };
  const handleSignDocument = async formLink => {
    setProscoreDocumentOpen(true);
    setSessionId(formLink);
  };

  const onProscoreDocumentClose = () => {
    setProscoreDocumentOpen(false);
  };

  const handleCloseMessage = () => {
    setOpenMessage(false);
  };

  const getAllDocuments = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const payload = {
      pageIndex: searchString ? 1 : currentPage + 1,
      pageSize: pageSize,
      searchString: searchString,
      orderByAscending: true,
      orderCol: "formName",
      ...sortModel
    };

    const { data, totalRecords, error } = await postApi(
      `${GET_ALL_APPRENTICE_MANAGEMENT_DOCUMENTS}?employeeId=${id}&offsetValue=${dayjs().utcOffset()}`,
      payload
    );
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    setPageState(prevPageInfo => ({ ...prevPageInfo, data: data || [], rowCount: totalRecords }));
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus]);

  useEffect(() => {
    getAllDocuments();
  }, [getAllDocuments]);

  const getEmployeeProjectByEmployeeGuid = async () => {
    const response = await getApi(`${GET_ALL_APPRENTICE_MANAGEMENT_EMPLOYEE_BY_ID}/${id}`);
    if (!response.isApprenticeable || (!response.assignRAPInfoGuid && !response.assignRAPWageScaleGuid)) {
      setModalMessage("The Apprentice is not assigned to a job title or that job title is not Apprenticeable.");
      setOpenMessage(true);
    } else if (!response.journeyWorkersWage) {
      setModalMessage("The Journey worker wage is set to zero in the wage rate defined for the job title assigned to the Apprentice.");
      setOpenMessage(true);
    } else {
      setModalMessage("");
      setOpenMessage(false);
    }
  };

  const convertToNewFormat = (originalObject, parentKey = "") => {
    const transformedObject = {};
    for (const key in originalObject) {
      const newKey = key.charAt(0).toUpperCase() + key.slice(1);
      const isChkOrRdKey = newKey.includes("Chk") || newKey.includes("Rd");

      if (Array.isArray(originalObject[key])) {
        transformedObject[newKey] = originalObject[key].map(item => {
          if (typeof item === "object" && item !== null) {
            return convertToNewFormat(item, newKey);
          } else {
            return { value: isChkOrRdKey ? item !== null && item !== "" : item };
          }
        });
      } else if (typeof originalObject[key] === "object" && originalObject[key] !== null) {
        Object.assign(transformedObject, convertToNewFormat(originalObject[key], newKey));
      } else {
        transformedObject[newKey] = { value: isChkOrRdKey ? originalObject[key] !== null && originalObject[key] !== "" : originalObject[key] };
      }
    }

    return transformedObject;
  };

  const handleSignDocumentClose = () => {
    getAllDocuments();
    setOpenSignDocument(false);
  };

  const uploadDocumentFromURL = async (docData, key, type, dataPayload) => {
    try {
      const apiKey = PANDADOC_API_KEY;
      const formName = type == "ETA" ? "ETA Form Document" : "Appendix B Document";
      const templateUUId = type == "ETA" ? ETA671_TEMPLATE_UUID : APPENDIXB_TEMPLATE_UUID;
      const uploadResponse = await fetch(`${PANDADOC_API_URI}/public/v1/documents`, {
        method: "POST",
        headers: {
          Authorization: `API-Key ${apiKey}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          name: formName,
          template_uuid: templateUUId,
          recipients: [
            {
              email: dataPayload.emailAddress,
              role: "apprentice",
              first_name: dataPayload.firstName,
              last_name: dataPayload.lastName,
              signing_order: 1
            },
            {
              email: PROSCORE_ADMIN_EMAIL,
              role: "proscoreadmin",
              first_name: PROSCORE_ADMIN_FIRST_NAME,
              last_name: PROSCORE_ADMIN_LAST_NAME,
              signing_order: 2
            }
          ],
          fields: docData,
          parse_form_fields: true
        })
      });
      if (uploadResponse) {
        const resp = await uploadResponse.json();
        setApprenticeEmail(dataPayload.emailAddress);
        setApprenticeFirstName(dataPayload.firstName);
        setApprenticeLastName(dataPayload.lastName);
        setDocumentId(resp.id);
        setFormName(resp.name);
        setAPIKey(apiKey);
        GetDocument(apiKey, resp.id, key, dataPayload.emailAddress);
      } else {
        const errorResponse = await uploadResponse.json();
        throw new Error(`Failed to upload document: ${errorResponse.error}`);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const GetDocument = async (apiKey, documentId, employeeGuid, apprenticeEmail) => {
    const obj = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `API-Key ${apiKey}`
      }
    };
    await fetch(`${PANDADOC_API_URI}/public/v1/documents/${documentId}`, obj)
    .then(response => response.json())
    .then(response => {
      if (response.status == "document.draft") {
        sendDocument(apiKey, documentId, employeeGuid, apprenticeEmail);
      } else {
        GetDocument(apiKey, documentId, employeeGuid, apprenticeEmail);
      }
    });
  };

  const sendDocument = async (apiKey, documentId, employeeGuid, apprenticeEmail) => {
    const obj = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `API-Key ${apiKey}`
      },
      body: JSON.stringify({
        silent: true,
        to: apprenticeEmail,
        subject: "Document for Signature",
        message: "Please review and sign the document."
      })
    };

    await fetch(`${PANDADOC_API_URI}/public/v1/documents/${documentId}/send`, obj)
      .then(response => response.json())
      .then(data => {
        const { name, id, status, recipients } = data;
        setSessionId(recipients?.[1].shared_link);
        setOpen(true);
        InsertEmployeeFormsData(employeeGuid, name, id, status, recipients[0]?.shared_link, recipients[1]?.shared_link);
      })
      .catch(error => console.error("Error:", error));
  };

  const InsertEmployeeFormsData = async (employeeGuid, documentName, documentId, documentStatus, firstSignerSharedLink, secondSignerSharedLink) => {
    const payload = {
      employeeGuid: employeeGuid,
      documentId: documentId,
      formName: documentName,
      firstSignerSharedLink: firstSignerSharedLink,
      secondSignerSharedLink: secondSignerSharedLink,
      docStatus: documentStatus,
      isApprenticeSigned: false,
      isProscoreSigned: false
    };
    const { error } = await postApi(ADD_EMPLOYEE_FORMS, payload);
    if (error) return errorToast(error);
    setTextButton("Create ETA671 Form");
    setTextButton1("Create Appendix B document");
  };

  const sendNotificationForDocumentSignatures = async () => {
    setSendDocumentStatus(true);
    const payload = {
      email: apprenticeEmail,
      firstName: apprenticeFirstName,
      lastName: apprenticeLastName,
      documentId: documentId,
      formName: formName
    };
    const { error } = await postApi(SEND_NOTIFICATION_FOR_DOCUMENT_SIGNATURE, payload);
    if (error) return errorToast(error);
    setSendDocumentStatus(false);
    setIsDocumentSent(true);
    setOpenAlert(true);
  };

  const GetETA671Form = async () => {
    setTextButton("Loading...");
    let response = await getApi(`${GET_ALL_APPRENTICE_ETAFORM671}/${id}`);
    if (response) {
      response.ssn = decryptSSN(response.ssn);
      const convertedObject = convertToNewFormat(response);
      const wageRateList = response.wageRateListData;
      if (wageRateList != null) {
        for (let i = 1; i <= wageRateList.length; i++) {
          convertedObject["Duration" + i] = { value: wageRateList[i - 1].startHours + "-" + wageRateList[i - 1].endHours };
          convertedObject["WageRate" + i] = { value: wageRateList[i - 1].wageRate };
        }
      }
      delete convertedObject["WageRateListData"];
      uploadDocumentFromURL(convertedObject, id, "ETA", response);
    }
  };

  const GetAppendixBDocument = async () => {
    setTextButton1("Loading...");
    let response = await getApi(`${GET_ALL_APPRENTICE_APENDIXBRECORDS}/${id}`);
    if (response) {
      response.ssn = decryptSSN(response.ssn);
      const convertedObject = convertToNewFormat(response);
      const wageRateList = response.wageRateListData;
      if (wageRateList != null) {
        for (let i = 1; i <= wageRateList.length; i++) {
          convertedObject["Duration" + i] = { value: wageRateList[i - 1].startHours + "-" + wageRateList[i - 1].endHours };
          convertedObject["WageRate" + i] = { value: wageRateList[i - 1].wageRateAmount };
        }
      }
      delete convertedObject["WageRateListData"];
      uploadDocumentFromURL(convertedObject, id, "ETA", response);
    }
  };

  const handleClose = () => {
    setOpen(false);
    getAllDocuments();
  };

  const getFormStatus = async () => {
    setStatus(STAUTES.LOADING);
    const {isFormStatusCompleted} = await getApi(`${GET_FORM_STATUS}/${id}`);
    setButtonStatus(!isFormStatusCompleted)
    setStatus(STAUTES.IDLE);
  };

  useEffect(() => {
    getEmployeeProjectByEmployeeGuid();
    getFormStatus();
  }, []);

  return (
    <>
      <TabGridAndFormLayout showGrid={true} title={"Documents"} beingEdited={false} canView={true} hideEdit={false}>
        <ProGrid
          title="Documents"
          loading={status === STAUTES.LOADING}
          options={{
            getRowId: row => row.employeeFormsGuid,
            rowCount: rowCount,
            paginationMode: "server",
            paginationModel: { pageSize: pageSize, page: currentPage },
            onPaginationModelChange: setPagination,
            sortingMode: "server",
            onSortModelChange: setSortModel
          }}
          columns={columns}
          rows={documentData}
          searchMode={!!searchString}
          searchModel={{ defaultValue: searchString, handleDebounce: setSearchString }}
          hideExport={false}
          hideAddButton={true}
          additionButtions={
            <>
              <ProButton tooltipText={buttonStatus ? "You don't have SSN" : ""} disabled={buttonStatus} onClick={GetETA671Form}>{textButton}</ProButton>
              <ProButton tooltipText={buttonStatus ? "You don't have SSN" : ""} disabled={buttonStatus} onClick={GetAppendixBDocument}>{textButton1}</ProButton>
            </>
          }
        />
      </TabGridAndFormLayout>
      {modalMessage ? (
        <Dialog
          open={openMessage}
          onClose={handleCloseMessage}
          maxWidth="md"
          fullWidth
          keepMounted
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Paper sx={{ py: 3, px: 2 }}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant="h5" component="h5">
                  {modalMessage}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Dialog>
      ) : null}

      <Dialog
        open={proscoreDocumentOpen}
        onClose={onProscoreDocumentClose}
        maxWidth="md"
        fullWidth
        keepMounted
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={{ width: "100%" }}>
          <IconButton style={{ position: "absolute", right: 0, padding: "2px" }} onClick={onProscoreDocumentClose}>
            <CloseIcon />
          </IconButton>
          <Paper sx={{ py: 3, px: 2 }}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant="h5" component="h5">
                  Sign Document
                </Typography>
                <Typography variant="subtitle2"></Typography>
              </Grid>
              <Grid item></Grid>
            </Grid>
          </Paper>

          <div>
            <iframe className="pandadox-iframe" title="PDF Viewer" width="100%" src={sessionId}></iframe>
          </div>
        </Box>
      </Dialog>

      <ReviewDocument
        open={open}
        onClose={handleClose}
        documentId={documentId}
        apiKey={aPIKey}
        sessionId={sessionId}
        onClickSendDocument={sendNotificationForDocumentSignatures}
        SendDocumentStatus={sendDocumentStatus}
        IsDocumentSent={isDocumentSent}
        openAlert={openAlert}
      />

      <SignDocument
        openSignDocument={openSignDocument}
        onClose={handleSignDocumentClose}
        documentId={documentId}
        apiKey={aPIKey}
        sessionId={sessionId}
      />
    </>
  );
}
