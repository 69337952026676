import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FormSectionWrapper from "../../../Components/ProForms/FormSectionWrapper";
import ProFormsLayout from "../../../Components/ProForms/ProFormsLayout";
import { useForm } from "react-hook-form";
import { STAUTES } from "../../../../utils/constant";
import ProTextInput from "../../../Components/Inputs/TextField";
import ProMobileField from "../../../Components/Inputs/MobileField";
import ProEmailField from "../../../Components/Inputs/EmailField";
import ProFileUploadField from "../../../Components/Inputs/FileUploadField";
import {
  changeMode,
  changeModeForField,
  convertFileToBase64Model,
  fieldTruePermission,
  prepareDefaultValues,
  prepareInitialConfig
} from "../../../../utils/formHelper";
import { errorToast, successToast } from "../../../../utils/toastHelper";
import ZipField from "../../../Components/Inputs/ZipField";
import UrlField from "../../../Components/Inputs/UrlField";
import { ADD_CLIENT, GET_CLIENT, UPDATE_CLIENT } from "../../../../utils/services/apiPath";
import { getApi, postApi, putApi } from "../../../../utils/services";
import { encryptText } from "../../../../utils/crypto";
import ProButton from "../../../Components/ProButton";

const ClientProfileFormConfing = {
  companyName: {
    label: "Company Name",
    rules: { required: "Company Name is required!" }
  },
  title: {
    label: "Title"
  },
  contactFirstName: {
    label: "Contact First Name",
    rules: { required: "Contact First Name is required!" }
  },
  contactLastName: {
    label: "Contact Last Name",
    rules: { required: "Contact Last Name is required!" }
  },
  address1: {
    label: "Address 1",
    rules: { required: "Address 1 is required!" }
  },
  address2: {
    label: "Address 2"
  },
  city: {
    label: "City",
    rules: { required: "City is required!" }
  },
  state: {
    label: "State",
    rules: { required: "State is required!" }
  },
  zip: {
    label: "Zip",
    rules: { required: "Zip is required!" }
  },
  website: {
    label: "Website"
  },
  contactPhone: {
    label: "Contact Phone",
    rules: {
      required: "Contact Phone is required!",
      rules: {
        pattern: {
          value: /^.{14}$/,
          message: "Whats App Number must be 10 digits"
        }
      }
    }
  },
  contactEmail: {
    label: "Contact Email",
    rules: { required: "Contact Email is required!" }
  },
  fax: {
    label: "Fax",
  },
  invitationCode: {
    label: "Invitation Code"
  },
  logoMediaModel: {
    label: "Logo",
    defaultValue: null
  }
};

export default function ClientProfile() {
  const navigate = useNavigate();
  const { id } = useParams();
  const isAddMode = useMemo(() => id === "add", [id]);
  const [clientProfileData, setClientProfileData] = useState({});
  const [status, setStatus] = useState(STAUTES.IDLE);
  const [formConfig, setFormConfig] = useState(prepareInitialConfig(ClientProfileFormConfing, isAddMode ? "edit" : "read"));
  const { handleSubmit, control, setValue } = useForm({ defaultValues: prepareDefaultValues(ClientProfileFormConfing) });
  const [isSaving, setIsSaving] = useState(false);
  const isEditModeOn = useMemo(() => Object.values(formConfig).some(({ mode }) => mode === "edit"), [formConfig]);
  const backAction = () => navigate("/clients");

  const onEdit = () => setFormConfig(changeMode("edit"));
  const onCancel = () => (isAddMode ? backAction() : setFormConfig(changeMode()));

  const handleReadValueclick = name => setFormConfig(changeModeForField(name, "edit"));

  function generatePassword(length) {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()-_=+";
    let password = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      password += characters[randomIndex];
    }
    if (!/[!@#$%^&*()\-_=+]/.test(password)) {
      const specialCharacters = "!@#$%^&*()-_=+";
      const randomSpecialChar = specialCharacters[Math.floor(Math.random() * specialCharacters.length)];
      const randomIndex = Math.floor(Math.random() * (password.length + 1));
      password = password.slice(0, randomIndex) + randomSpecialChar + password.slice(randomIndex);
    }

    return password;
  }

  const onSubmit = async data => {
    if (isSaving) return; 
    setIsSaving(true);
    setStatus(STAUTES.LOADING);
    const base64ProfilePicture = await convertFileToBase64Model(data.logoMediaModel);
    const password = generatePassword(8);
    const payload = {
      ...clientProfileData,
      ...data,
      password: password,
      encryptedPassword: encryptText(password),
      proscoreAdminPassword: encryptText(`@d1n4${data.companyName.replace(/\s+/g, "")}`),
      logoMediaModel: base64ProfilePicture || null
    };
    delete payload.logo;
    const method = isAddMode ? postApi : putApi;
    const { error } = await method(isAddMode ? ADD_CLIENT : UPDATE_CLIENT, payload);
    setIsSaving(false); 
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    successToast(`Client ${isAddMode ? "Added" : "Updated"} Successfully`);
    backAction();
  };

  const getClientProfileData = async id => {
    const res = await getApi(`${GET_CLIENT}${id}`);
    const data = {
      ...res,
      logoMediaModel: res?.logoMediaModel?.path
    };
    if (!res) return errorToast(error);
    setClientProfileData(data);
    Object.keys(formConfig).forEach(field => setValue(field, field === "logoMediaModel" ? null : data[field]));
  };

  useEffect(() => {
    if (!isAddMode) getClientProfileData(id);
  }, [id]);

  const defaultFormProps = { control, formValues: clientProfileData, isAddMode, handleReadValueclick };

  const handleGenerate = () => {
    setFormConfig(changeModeForField("invitationCode", "edit"));
    const result = Math.floor(100000 + Math.random() * 900000);
    setValue("invitationCode", result.toString());
  };

  return (
    <ProFormsLayout
      status={status}
      backAction={backAction}
      title={`${isAddMode ? "Add" : "Edit"} Client`}
      backLabel="Go Back to All Clients"
      beingEdited={isEditModeOn}
      onEdit={onEdit}
      onCancel={onCancel}
      onSave={handleSubmit(onSubmit)}
      isSaving={isSaving}
    >
      <FormSectionWrapper title={"CLIENT INFORMATION"}>
        <ProTextInput {...defaultFormProps} {...formConfig.companyName} permission={fieldTruePermission} />
        <ProTextInput {...defaultFormProps} {...formConfig.title} permission={fieldTruePermission} />
        <ProTextInput {...defaultFormProps} {...formConfig.contactFirstName} permission={fieldTruePermission} />
        <ProTextInput {...defaultFormProps} {...formConfig.contactLastName} permission={fieldTruePermission} />
        <ProTextInput {...defaultFormProps} {...formConfig.address1} permission={fieldTruePermission} />
        <ProTextInput {...defaultFormProps} {...formConfig.address2} permission={fieldTruePermission} />
        <ProTextInput {...defaultFormProps} {...formConfig.city} permission={fieldTruePermission} />
        <ProTextInput {...defaultFormProps} {...formConfig.state} permission={fieldTruePermission} />
        <ZipField {...defaultFormProps} {...formConfig.zip} permission={fieldTruePermission} />
        <UrlField {...defaultFormProps} {...formConfig.website} permission={fieldTruePermission} />
        <ProMobileField {...defaultFormProps} {...formConfig.contactPhone} permission={fieldTruePermission} />
        <ProEmailField {...defaultFormProps} {...formConfig.contactEmail} permission={fieldTruePermission} />
        <ProMobileField {...defaultFormProps} {...formConfig.fax} permission={fieldTruePermission} />
        {!isAddMode && (
          <>
            <ProTextInput {...defaultFormProps} {...formConfig.invitationCode} disabled={true} permission={fieldTruePermission} />
            <div style={{marginLeft:"15px",marginTop:"15px"}}> <ProButton onClick={handleGenerate}>Re-Generate</ProButton></div>
          </>
        )}
        <ProFileUploadField
          {...defaultFormProps}
          {...formConfig.logoMediaModel}
          inputProps={{ accept: "image/*" }}
          permission={fieldTruePermission}
        />
      </FormSectionWrapper>
    </ProFormsLayout>
  );
}
