import React, { useEffect, useRef } from "react";
import DebounceInput from "../../Inputs/DebounceInput";

let focus = false;
export default function GridSerch(searchModel) {
  const ref = useRef();
  const onFocus = () => {
    ref.current.autoFocus = true;
    focus = true;
  };
  const onOutSideFocus = event => {
    if (event.target.closest(".proGrid-search-wrapper") === null) {
      ref.current.autoFocus = false;
      focus = false;
    }
  };
  useEffect(() => {
    if (ref.current) {
      document.addEventListener("click", onOutSideFocus);
      if (focus) ref.current.focus();
    }
    return () => {
      document.removeEventListener("click", onOutSideFocus);
    };
  }, [ref.current]);
  return <DebounceInput placeholder="Search..." {...searchModel} inputRef={ref} onFocus={onFocus} />;
}
