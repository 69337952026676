import React from "react";
import Header from "../Header/Header";
import { ErrorBox } from "../Helpers/ErrorBox";
import PropTypes from "prop-types";

export default function Layout({ children, status, canView = true }) {

  return (
    <>
      <Header />
      {canView ? children : <ErrorBox message="You don't have permission to view this page." />}
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired, 
  status: PropTypes.string,          
  canView: PropTypes.bool,        
};