import React, { useCallback, useEffect, useMemo, useState } from "react";
import Layout from "../../../Components/Layout";
import { useNavigate, useParams } from "react-router-dom";
import ProTextInput from "../../../Components/Inputs/TextField";
import { useForm } from "react-hook-form";
import { Grid } from "@mui/material";
import SettingFormsLayout from "../../../Components/ProForms/ProFormsLayout/SettingFormsLayout";
import {
  changeMode,
  changeModeForField,
  checkEditEnabled,
  convertFileToBase64,
  fieldTruePermission,
  prepareDefaultValues,
  prepareInitialConfig
} from "../../../../utils/formHelper";
import { errorToast, successToast } from "../../../../utils/toastHelper";
import { STAUTES } from "../../../../utils/constant";
import EmailField from "../../../Components/Inputs/EmailField";
import FileUploadField from "../../../Components/Inputs/FileUploadField";
import { getApi, postApi, putApi } from "../../../../utils/services";
import { ADD_ADMIN_USER, GET_ADMIN_USER, UPDATE_ADMIN_USER } from "../../../../utils/services/apiPath";

export const adminUserFormConfig = {
  firstName: {
    label: "First Name",
    rules: { required: "First Name is required!" }
  },
  lastName: {
    label: "Last Name",
    rules: { required: "Last Name is required!" }
  },
  email: {
    label: "Email",
    rules: { required: "Email is required!" }
  },
  userName: {
    label: "User Name",
    rules: { required: "User Name is required!" }
  },
  profilePicture: {
    label: "Profile Picture",
    defaultValue: null
  }
};

export default function AdminUserForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const isAddMode = useMemo(() => id === "add", [id]);
  const [status, setStatus] = useState(STAUTES.IDLE);
  const [formConfig, setFormConfig] = useState(prepareInitialConfig(adminUserFormConfig, isAddMode ? "edit" : "read"));
  const { handleSubmit, control, setValue } = useForm({ defaultValues: prepareDefaultValues(adminUserFormConfig) });
  const [adminUserData, setAdminUserData] = useState({});
  const isEditModeOn = useMemo(() => checkEditEnabled(isAddMode, formConfig), [isAddMode, formConfig]);
  const [isSaving, setIsSaving] = useState(false);

  const backAction = () => navigate("/users");
  const onEdit = () => setFormConfig(changeMode("edit"));
  const onCancel = () => {
    if (isAddMode) backAction();
    else setFormConfig(changeMode());
  };

  const getDetailById = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const { error, ...rest } = await getApi(`${GET_ADMIN_USER}${id}`);
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    setAdminUserData(rest);
    Object.keys(adminUserFormConfig).forEach(field => setValue(field, rest[field]));
  }, [setValue, id]);

  const handleReadValueclick = name => setFormConfig(changeModeForField(name, "edit"));

  const onSubmit = async data => {
    if (isSaving) return; 
    setIsSaving(true);
    setStatus(STAUTES.LOADING);
    const base64String = await convertFileToBase64(data.profilePicture);
    const payload = {
      ...adminUserData,
      ...data,
      logoMediaModel: {
        base64String,
        fileName: data.profilePicture?.name || null
      }
    };
    delete payload.profilePicture;
    const method = isAddMode ? postApi : putApi;
    const { error } = await method(isAddMode ? ADD_ADMIN_USER : UPDATE_ADMIN_USER, payload);
    setIsSaving(false); 
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    successToast(`Admin User ${isAddMode ? "added" : "updated"} successfully.`);
    backAction();
  };

  useEffect(() => {
    if (!isAddMode) getDetailById(id);
  }, [id, isAddMode, getDetailById]);

  const defFormProps = { control, formValues: adminUserData, isAddMode, handleReadValueclick };
  return (
    <Layout status={status}>
      <SettingFormsLayout
        backAction={backAction}
        title={`${isAddMode ? "Add" : "Edit"} Admin User`}
        backLabel="Go Back to All Admin User"
        beingEdited={isEditModeOn}
        onEdit={onEdit}
        onCancel={onCancel}
        onSave={handleSubmit(onSubmit)}
        isSaving={isSaving}
      >
        <Grid container spacing={2}>
          <ProTextInput {...defFormProps} {...formConfig.firstName} permission={fieldTruePermission} />
          <ProTextInput {...defFormProps} {...formConfig.lastName} permission={fieldTruePermission} />
          <EmailField {...defFormProps} {...formConfig.email} permission={fieldTruePermission} />
          <ProTextInput {...defFormProps} {...formConfig.userName} permission={fieldTruePermission} />
          <FileUploadField {...defFormProps} {...formConfig.profilePicture} permission={fieldTruePermission} />
        </Grid>
      </SettingFormsLayout>
    </Layout>
  );
}
