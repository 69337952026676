import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleInitialize, loginSelector } from "../../store/features/auth/authSlice";
import Loading from "../Loading";
import PropTypes from "prop-types";

export default function AuthWrapper({ children }) {
  const dispatch = useDispatch();
  const { isMounted } = useSelector(loginSelector);

  useEffect(() => {
    dispatch(handleInitialize());
  }, [dispatch]);

  if (!isMounted) return <Loading />;
  return children;
}

AuthWrapper.propTypes = {
  children: PropTypes.node 
};
