import React from "react";
import PropTypes from "prop-types";

const ContactIcon = ({ color = "#00050B" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill={color}>
    <path
      fill={color}
      d="M9.143 6.284a1.143 1.143 0 1 1-2.287.002 1.143 1.143 0 0 1 2.287-.002ZM10 8.464v.309c0 .62-.851 1.084-2 1.084s-2-.464-2-1.083v-.31A.463.463 0 0 1 6.462 8h3.076a.463.463 0 0 1 .462.464ZM4.429 1.428A1.857 1.857 0 0 0 2.57 3.286v9.428a1.857 1.857 0 0 0 1.858 1.857H13a.429.429 0 1 0 0-.857H4.429a1 1 0 0 1-.99-.857H13a.429.429 0 0 0 .429-.428V3.285a1.857 1.857 0 0 0-1.858-1.858H4.43Zm8.142 1.858V12H3.43V3.286a1 1 0 0 1 1-1h7.142a1 1 0 0 1 1 1Z"
    />
  </svg>
);

ContactIcon.propTypes = {
  color: PropTypes.string,
};

export default ContactIcon;
