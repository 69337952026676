import CryptoJS from "crypto-js";
import { BASE_PASS_KEY, BASE_SSN_KEY } from "./constant";

export const encryptText = text => {
  const key = BASE_PASS_KEY;
  const keyutf = CryptoJS.enc.Utf8.parse(key);
  const iv = CryptoJS.enc.Base64.parse(key);
  const enc = CryptoJS.AES.encrypt(text, keyutf, { iv: iv });
  const encStr = enc.toString();
  return encStr;
};

export const decryptText = data => {
  const key = BASE_PASS_KEY;
  const keyutf = CryptoJS.enc.Utf8.parse(key);
  const iv = CryptoJS.enc.Base64.parse(key);
  const dec = CryptoJS.AES.decrypt(data, keyutf, { iv: iv });
  const decStr = dec.toString(CryptoJS.enc.Utf8);
  return JSON.parse(decStr);
};

export const encryptSSN = ssn => {
  if (!ssn) return null;
  const key = CryptoJS.enc.Utf8.parse(BASE_SSN_KEY);
  return CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(ssn), key, {
    keySize: 128 / 8,
    iv: key,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  }).toString();
};

export const decryptSSN = ssn => {
  if (!ssn) return null;
  try {
    const key = CryptoJS.enc.Utf8.parse(BASE_SSN_KEY);
    const data = CryptoJS.AES.decrypt(ssn, key, {
      keySize: 128 / 8,
      iv: key,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return data.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    return ssn;
  }
};
