import React, { useCallback, useEffect, useMemo, useState } from "react";
import ProFormsLayout from "../../../Components/ProForms/ProFormsLayout";
import FormSectionWrapper from "../../../Components/ProForms/FormSectionWrapper";
import { useNavigate, useParams } from "react-router-dom";
import {
  changeMode,
  changeModeForField,
  convertResponseDate,
  fieldTruePermission,
  formatPayloadDate,
  lessThan16Years,
  prepareDefaultValues,
  prepareInitialConfig
} from "../../../../utils/formHelper";
import { STAUTES } from "../../../../utils/constant";
import { useForm } from "react-hook-form";
import ProTextInput from "../../../Components/Inputs/TextField";
import MobileField from "../../../Components/Inputs/MobileField";
import ProSelectField from "../../../Components/Inputs/SelectField";
import EmailField from "../../../Components/Inputs/EmailField";
import ProSsnField from "../../../Components/Inputs/SSNField";
import ProMultiSelectSelectField from "../../../Components/Inputs/MultiSelectField";
import DateField from "../../../Components/Inputs/DateField";
import Loading from "../../../Components/Loading";
import {
  GET_ALL_APPRENTICE_EMPLOYEE,
  GET_ALL_APPRENTICE_PROGRAM,
  GET_ALL_CAREER_CONNECTION,
  GET_ALL_DISABILITY,
  GET_ALL_EDUCATION,
  GET_ALL_EMPLOYEE_STATUS,
  GET_ALL_ETHNICITY,
  GET_ALL_RACETYPE,
  GET_ALL_VETERANSTATUS,
  UPDATE_APPRENTICE_EMPLOYEE
} from "../../../../utils/services/apiPath";
import { getApi, postApi } from "../../../../utils/services";
import { sexOptions, transFormModalData } from "../../../../utils/dropdownHelper";
import { errorToast, successToast } from "../../../../utils/toastHelper";
import { decryptSSN, encryptSSN } from "../../../../utils/crypto";

const RegistrationFormConfig = {
  firstName: {
    label: "First Name",
    perKey: "firstName"
  },
  middleName: {
    label: "Middle Name",
    perKey: "middleName"
  },
  lastName: {
    label: "Last Name",
    perKey: "lastName"
  },
  cellPhone: {
    label: "Cell Phone",
    perKey: "cellPhone"
  },
  email: {
    label: "Email",
    perKey: "email"
  },
  last4ofSSN: {
    label: "SSN",
    perKey: "last4ofSSN"
  },
  dateofBirth: {
    label: "Date Of Birth",
    perKey: "dateofBirth",
    defaultValue: null,
    rules: {
      validate: lessThan16Years
    }
  },
  sex: {
    label: "Sex",
    perKey: "Sex"
  },
  apprenticeshipRegNumber: {
    label: "Apprenticeship Number",
    perKey: "apprenticeshipRegNumber"
  },
  ethnicityTypeId: {
    label: "Ethnicity",
    perKey: "ethnicityTypeId"
  },
  raceTypeId: {
    label: "Race",
    perKey: "raceTypeId"
  },
  educationLevelTypeId: {
    label: "Educational Level",
    perKey: "educationLevelTypeId"
  },
  disabilityTypeId: {
    label: "Disability",
    perKey: "disabilityTypeId"
  },
  employmentStatus: {
    label: "Employment Status",
    perKey: "employmentStatus"
  },
  veteranStatusTypeId: {
    label: "Veteran Status",
    perKey: "veteranStatusTypeId"
  },
  rapInfoId: {
    label: "Apprenticeships Program",
    perKey: "rapInfoId"
  },
  careerConnectionTypeId: {
    label: "Career Connection",
    perKey: "careerConnectionTypeId"
  },
  dateApprenticeshipBegins: {
    label: "Date Apprenticeship Begins",
    defaultValue: null,
    perKey: "dateApprenticeshipBegins"
  },
  anyCredit: {
    label: "Learning Experience",
    perKey: "anyCredit"
  },
  rti: {
    label: "Instruction Experience",
    perKey: "instructionExperience"
  },
  address: {
    label: "Address",
    perKey: "address"
  },
  address2: {
    label: "Address2",
    perKey: "address2"
  },
  state: {
    label: "State",
    perKey: "state"
  },
  city: {
    label: "City",
    perKey: "city"
  },
  zip: {
    label: "Zip",
    perKey: "zip"
  }
};
export const employmentStatusOptions = [
  {
    label: "New Employee",
    value: "New"
  },
  {
    label: "Current Employee",
    value: "Current"
  }
];

const ApprenticeProfile = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const isAddMode = useMemo(() => id === "add", [id]);
  const [ethiniCity, setEthiniCity] = useState([]);
  const [veteranStatus, setVeteranStatus] = useState([]);
  const [race, setRace] = useState([]);
  const [educationLevel, setEducationLevel] = useState([]);
  const [careerConnectionTypeModel, setCareerConnectionTypeModel] = useState();
  const [disability, setDisability] = useState();
  const [employeeStatus, setEmployeeStatus] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(STAUTES.IDLE);
  const [apprenticeShipList, setApprenticeShipList] = useState();
  const [registrationFormData, setRegistrationFormData] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [formConfig, setFormConfig] = useState(prepareInitialConfig(RegistrationFormConfig));
  const { handleSubmit, control, setValue } = useForm({ defaultValues: prepareDefaultValues(RegistrationFormConfig) });

  useEffect(() => {
    setFormConfig(changeMode("edit"));
  }, []);

  const isEditModeOn = useMemo(() => Object.values(formConfig).some(({ mode }) => mode === "edit"), [formConfig]);
  const onEdit = () => setFormConfig(changeMode("edit"));

  const handleReadValueclick = name => setFormConfig(changeModeForField(name, "edit"));

  const onCancel = () => {
    navigate("/apprentice-management");
    isAddMode ? backAction() : setFormConfig(changeMode("edit"));
  };

  const fetchAllEthnicityTypes = async () => {
    const res = await getApi(`${GET_ALL_ETHNICITY}/${id}`);
    setEthiniCity(res?.map(item => ({ label: item.label, value: item.value })));
  };

  const fetchAllVeteranStatusTypes = async () => {
    const res = await getApi(`${GET_ALL_VETERANSTATUS}/${id}`);
    setVeteranStatus(res?.map(item => ({ label: item.label, value: item.value })));
  };

  const fetchAllRaceTypes = async () => {
    const res = await getApi(`${GET_ALL_RACETYPE}/${id}`);
    setRace(res?.map(item => ({ label: item.label, value: item.value })));
  };

  const fetchAllEducationLevelTypes = async () => {
    const res = await getApi(`${GET_ALL_EDUCATION}/${id}`);
    setEducationLevel(res?.map(item => ({ label: item.label, value: item.value })));
  };

  const fetchAllDisabilityTypes = async () => {
    const res = await getApi(`${GET_ALL_DISABILITY}/${id}`);
    setDisability(res?.map(item => ({ label: item.label, value: item.value })));
  };

  const fetchAllCareerConnectionType = async () => {
    const res = await getApi(`${GET_ALL_CAREER_CONNECTION}/${id}`);
    setCareerConnectionTypeModel(res?.map(item => ({ label: item.label, value: item.value })));
  };

  const fetchAllApprenticeshipList = async () => {
    const res = await getApi(`${GET_ALL_APPRENTICE_PROGRAM}/${id}`);
    setApprenticeShipList(res?.map(item => ({ label: item.label, value: item.value })));
  };

  const fetchAllEmployeeStatusList = async () => {
    const res = await getApi(`${GET_ALL_EMPLOYEE_STATUS}`);
    setEmployeeStatus(res?.map(item => ({ label: item.label, value: item.value })));
  };

  const getProScoreEmployees = useCallback(async () => {
    setIsLoading(true);
    const { error, data: employee } = await getApi(`${GET_ALL_APPRENTICE_EMPLOYEE}/${id}`);
    if (error) return errorToast(error);
    const {
      employeeStatusTypeIds,
      ethnicityTypeModel,
      veteranStatusTypeModel,
      raceTypeModel,
      educationLevelTypeModel,
      disabilityTypeModel,
      rapInfoModel,
      careerConnectionTypeModel,
      apprenticeStatusTypeModel,
      managerIds,
      languageTypeIds,
      notificationTypeIds,
      employmentTermTypeIds,
      fringePaymentMethodTypeIds,
      birthDate,
      dateApprenticeshipBegins,
      profileMediaModel,
      last4ofSSN
    } = employee;
    const data = {
      ...employee,
      last4ofSSN: decryptSSN(last4ofSSN),
      dateApprenticeshipBegins: convertResponseDate(dateApprenticeshipBegins),
      dateofBirth: convertResponseDate(birthDate),
      veteranStatusTypeId: veteranStatusTypeModel,
      raceTypeId: raceTypeModel,
      profilePicture: profileMediaModel?.path,
      ...transFormModalData({
        employeeStatusTypeId: employeeStatusTypeIds,
        ethnicityTypeId: ethnicityTypeModel,
        educationLevelTypeId: educationLevelTypeModel,
        rapInfoId: rapInfoModel,
        disabilityTypeId: disabilityTypeModel,
        careerConnectionTypeId: careerConnectionTypeModel,
        apprenticeStatusTypeId: apprenticeStatusTypeModel,
        fringePaymentMethodType: fringePaymentMethodTypeIds,
        manager: managerIds,
        languageType: languageTypeIds,
        notificationType: notificationTypeIds,
        employmentTermTypeId: employmentTermTypeIds
      })
    };
    setRegistrationFormData(data);
    Object.keys(formConfig).forEach(field => setValue(field, data[field]));
    setIsLoading(false);
  });

  const onSubmit = async data => {
    if (isSaving) return;
    setIsSaving(true);
    setIsLoading(true);
    const payload = {
      ...data,
      employeeId: id,
      birthDate: formatPayloadDate(data?.dateofBirth),
      dateApprenticeshipBegins: formatPayloadDate(data?.dateApprenticeshipBegins),
      raceTypeId: data.raceTypeId?.map(({ value }) => value) || null,
      last4ofSSN: encryptSSN(data?.last4ofSSN),
      veteranStatusTypeId: data.veteranStatusTypeId?.map(({ value }) => value) || null
    };
    setStatus(STAUTES.IDLE);
    const { error } = await postApi(UPDATE_APPRENTICE_EMPLOYEE, payload);
    setIsSaving(false);
    setStatus(STAUTES.IDLE);
    setFormConfig(changeMode("edit"));
    if (error) return errorToast(error);
    successToast(`Registration ${isAddMode ? "Added" : "Updated"} Successfully`);
    getProScoreEmployees();
    setIsLoading(false);
  };

  useEffect(() => {
    fetchAllEthnicityTypes();
    fetchAllVeteranStatusTypes();
    fetchAllRaceTypes();
    fetchAllEducationLevelTypes();
    fetchAllDisabilityTypes();
    fetchAllCareerConnectionType();
    getProScoreEmployees();
    fetchAllApprenticeshipList();
    fetchAllEmployeeStatusList();
    if (isAddMode) onEdit();
    if (!isAddMode) setRegistrationFormData(id);
  }, [id]);

  const defaultFormProps = { control, formValues: registrationFormData, isAddMode, handleReadValueclick };
  return (
    <ProFormsLayout
      status={status}
      hideBackbutton={true}
      title={"Apprentice Profile"}
      beingEdited={isEditModeOn}
      canView={true}
      hideEdit={false}
      onEdit={onEdit}
      onCancel={onCancel}
      onSave={handleSubmit(onSubmit)}
      buttonLabel={"Save"}
      isSaving={isSaving}
    >
      {!isLoading ? (
        <>
          <FormSectionWrapper title={"Basic Information"}>
            <ProTextInput {...defaultFormProps} {...formConfig.firstName} permission={fieldTruePermission} />
            <ProTextInput {...defaultFormProps} {...formConfig.middleName} permission={fieldTruePermission} />
            <ProTextInput {...defaultFormProps} {...formConfig.lastName} permission={fieldTruePermission} />
            <MobileField {...defaultFormProps} {...formConfig.cellPhone} permission={fieldTruePermission} />
            <EmailField {...defaultFormProps} {...formConfig.email} permission={fieldTruePermission} />
            <ProSsnField {...defaultFormProps} {...formConfig.last4ofSSN} permission={fieldTruePermission} />
            <DateField {...defaultFormProps} {...formConfig.dateofBirth} permission={fieldTruePermission} />
            <ProSelectField {...defaultFormProps} {...formConfig.sex} permission={fieldTruePermission} options={sexOptions} />
            <ProTextInput {...defaultFormProps} {...formConfig.apprenticeshipRegNumber} permission={fieldTruePermission} />
          </FormSectionWrapper>
          <FormSectionWrapper title={"Apprentice Information"}>
            <ProSelectField {...defaultFormProps} {...formConfig.ethnicityTypeId} permission={fieldTruePermission} options={ethiniCity} />
            <ProMultiSelectSelectField
              {...defaultFormProps}
              {...formConfig.veteranStatusTypeId}
              permission={fieldTruePermission}
              options={veteranStatus}
            />
            <ProMultiSelectSelectField {...defaultFormProps} {...formConfig.raceTypeId} permission={fieldTruePermission} options={race} />
            <ProSelectField {...defaultFormProps} {...formConfig.educationLevelTypeId} permission={fieldTruePermission} options={educationLevel} />
            <ProSelectField {...defaultFormProps} {...formConfig.disabilityTypeId} permission={fieldTruePermission} options={disability} />
            <ProSelectField {...defaultFormProps} {...formConfig.employmentStatus} permission={fieldTruePermission} options={employeeStatus} />
            <ProSelectField {...defaultFormProps} {...formConfig.rapInfoId} permission={fieldTruePermission} options={apprenticeShipList} />
            <ProSelectField
              {...defaultFormProps}
              {...formConfig.careerConnectionTypeId}
              permission={fieldTruePermission}
              options={careerConnectionTypeModel}
            />
            <DateField {...defaultFormProps} {...formConfig.dateApprenticeshipBegins} permission={fieldTruePermission} />
            <ProTextInput {...defaultFormProps} {...formConfig.anyCredit} permission={fieldTruePermission} />
            <ProTextInput {...defaultFormProps} {...formConfig.rti} permission={fieldTruePermission} />
          </FormSectionWrapper>
          <FormSectionWrapper title={"Address Information"}>
            <ProTextInput {...defaultFormProps} {...formConfig.address} permission={fieldTruePermission} />
            <ProTextInput {...defaultFormProps} {...formConfig.address2} permission={fieldTruePermission} />
            <ProTextInput {...defaultFormProps} {...formConfig.state} permission={fieldTruePermission} />
            <ProTextInput {...defaultFormProps} {...formConfig.city} permission={fieldTruePermission} />
            <ProTextInput {...defaultFormProps} {...formConfig.zip} permission={fieldTruePermission} />
          </FormSectionWrapper>
        </>
      ) : (
        <Loading />
      )}
    </ProFormsLayout>
  );
};

export default ApprenticeProfile;
