import "./App.scss";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import AuthWrapper from "./App/Components/AuthWrapper";
import { RouterProvider } from "react-router-dom";
import router from "./App/RouterComponent/RouterComponent";
import { Suspense, useEffect } from "react";
import Loading from "./App/Components/Loading";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addWatchForBuildVesion } from "./utils/watchForBuild";

const App = () => {
  useEffect(() => {
    addWatchForBuildVesion();
  }, []);
  return (
    <Suspense fallback={<Loading />}>
      <AuthWrapper>
        <RouterProvider router={router} />
      </AuthWrapper>
      <ToastContainer theme="colored" />
    </Suspense>
  );
};

export default App;
