import React from "react";
import "./index.scss";
import logo from "../../Assets/Icons/logo.png";
import logoWhite from "../../Assets/Icons/logo-white.png";
import { useTheme } from "../../ContextStore/ThemeContext";
import { BASE_URL } from "../../../utils/constant";
import PropTypes from "prop-types";

const ProPreLoginLayout = ({ heading, content, children }) => {
  const { theme } = useTheme();
  const cellStyles = theme === "dark" ? { background: "#202328" } : { background: "#e9f9ff" };
  const cellStyle = theme === "dark" ? { background: "#15171C" } : { background: "#ffffff" };
  const fontStyle = theme === "dark" ? { color: "#ffffff" } : { color: "#000000" };

  return (
    <div className="prelogin-layout" style={cellStyles}>
      <div className="prelogin-layout_container">
        <div className="prelogin-layout_logo">
          <img src={theme === "dark" ? logoWhite : logo} alt="logo" />
        </div>
        <div className="prelogin-layout_card" style={cellStyle}>
          <div className="prelogin-layout_card_heading">
            <h1 style={fontStyle}>{heading ?? "LogIn"}</h1>
            {content && <p style={fontStyle}>{content}</p>}
          </div>
          {children}
        </div>
      </div>
      <div className="preLogin-footer">
        <a className="preLogin-footer-link" href={`${BASE_URL}/privacy-policy/`}>
          Privacy Policy
        </a>
        <a className="preLogin-footer-link" href={`${BASE_URL}/terms-conditions/`}>
          Terms of Service
        </a>
        <a className="preLogin-footer-link" href={`${BASE_URL}/cookie-policy/`}>
          Cookie Policy
        </a>
        <a className="preLogin-footer-link" href={`${BASE_URL}/eula/`}>
          EULA
        </a>
      </div>
    </div>
  );
};

export default ProPreLoginLayout;

ProPreLoginLayout.propTypes = {
  heading: PropTypes.string,
  content: PropTypes.string,
  children: PropTypes.node.isRequired,
};
