import dayjs from "dayjs";
import { DEFAULT_DATE_FORMAT, DEFAULT_DATE_PAYLOAD_FORMAT } from "./constant";

// constant for form name
export const addJobTitleFormName = "Add Job Title";
export const listJobTitleFormName = "Job Title List";
export const addJobCategoryFormName = "Add Job Category";
export const listJobCategoryFormName = "Job Category LIst";
export const listUserFormName = "Users List";
export const addUserFormName = "Add User";

export const employeeNotesFormName = ["Add Notes", "Notes List"];
export const employeeDocumentsFormName = "Document List";
export const employeeProfileFormName = [
  "Basic Information",
  "Apprentice Information",
  "Address Information",
  "Mailing Address Inforamtion",
  "Additional Information"
];
export const employeeSocialMediaFormName = ["Add Social Media", "Social Media List"];
export const employeeTimeSheetFormName = "Time Sheets List";

export const partnersProfile = ["Partner Information", "Mailing Address", "Billing Address"];
export const partnersList = "Partners List";

export const projectJobsFormName = ["Add Job Detail or Job Detail", "Job List"];

export const fieldTruePermission = {
  canView: true,
  canAdd: true,
  canEdit: true
};

export const prepareDefaultValues = fieldInfo => {
  const defaultValues = {};
  Object.keys(fieldInfo).forEach(field => {
    if (fieldInfo[field].defaultValue === undefined) defaultValues[field] = "";
    else defaultValues[field] = fieldInfo[field].defaultValue;
  });
  return defaultValues;
};

export const prepareInitialConfig = (fieldInfo, mode = "read") => {
  const initialConfig = {};
  Object.keys(fieldInfo).forEach(field => (initialConfig[field] = { name: field, ...fieldInfo[field], mode }));
  return initialConfig;
};

export const changeMode =
  (mode = "read") =>
  formConfig => {
    return Object.keys(formConfig).reduce((acc, key) => ({ ...acc, [key]: { ...formConfig[key], mode } }), {});
  };

export const changeModeForField = (field, mode) => formConfig => {
  return { ...formConfig, [field]: { ...formConfig[field], mode } };
};

export const checkEditEnabled = (isAddMode, formConfig) => isAddMode || Object.values(formConfig).some(({ mode }) => mode === "edit");

export const convertResponseDate = date => (date ? dayjs(date) : null);

export const formatPayloadDate = date => {
  if (!date) return null;
  if (!dayjs(date).isValid) return null;
  return dayjs(date).format(DEFAULT_DATE_PAYLOAD_FORMAT);
};

export const convertResponseDateTime = time => {
  if (!time) return null;
  const [h, m, s] = time.split(":");
  return dayjs().hour(+h).minute(+m).second(+s);
};

export const convertTimetoResponse = time => (time ? time.format("HH:mm:ss") : null);

export const lessThan16Years = value => {
  if (!value) return true;
  if (value.isValid && !value.isValid()) return "Enter valid date";
  return value ? dayjs().diff(dayjs(value), "years") >= 16 || "Age should be great then 16 years" : true;
};

export const convertFileToBase64 = async file => {
  if (!file) return null;
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader?.result?.split(",")[1]);
    reader.onerror = error => reject(new Error(error));
    reader.readAsDataURL(file);
  });
};

export const convertFileToBase64Model = async file => {
  if (file === null || file === undefined) return null;
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () =>
      resolve({
        base64String: reader?.result?.split(",")[1],
        fileName: file?.name
      });
    reader.onerror = error => reject(new Error(error));
    reader.readAsDataURL(file);
  });
};

export const hoursFormatter = hour => {
  if (hour === undefined || hour === null) return "-";
  return `${Math.floor(hour)} Hrs ${Math.round((hour - Math.floor(hour)) * 60)} Min`;
};

export const roundNumber = num => {
  if (!Number(num)) return 0;
  return Math.round(Number(num) * 100) / 100;
};

export const renderFormattedDate = date => {
  if (!date) return "";
  if (!dayjs(date).isValid) return "";
  return dayjs(date).format(DEFAULT_DATE_FORMAT);
};

export const downloadFile = (url, fileName) => {
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;
  link.target = "_blank";
  link.rel = "noopener noreferrer";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const base64ToBlob = base64String => {
  const byteCharacters = atob(base64String);
  const byteNumbers = new Uint8Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  return new Blob([byteNumbers], { type: "application/octet-stream" });
};

export const sortOptions = options => {
  return options.sort((a, b) => a.label?.localeCompare(b.label));
};
