import React from "react";
import PropTypes from "prop-types"; 
import { Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

const genrateOptions = options => {
  return options.map(option => {
    return (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    );
  });
};

export default function DetailSubHeader({ value, onChange, tabs }) {
  const options = tabs.map(tab => ({ value: tab.title, label: tab.title }));

  const handleChange = event => {
    onChange(tabs.find(tab => tab.title === event.target.value));
  };

  return (
    <div className="detail-sub-header">
      <Select onChange={handleChange} value={value ?? ""} variant="outlined" fullWidth>
        {genrateOptions(options)}
      </Select>
    </div>
  );
}

DetailSubHeader.propTypes = {
  value: PropTypes.string, 
  onChange: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired
    })
  ).isRequired
};
