import React, { useEffect, useMemo, useState } from "react";
import { changeMode, fieldTruePermission, prepareDefaultValues, prepareInitialConfig } from "../../../../utils/formHelper";
import { useForm } from "react-hook-form";
import { STAUTES } from "../../../../utils/constant";
import { CircularProgress, Grid } from "@mui/material";
import ProTextInput from "../../../Components/Inputs/TextField";
import ProButton from "../../../Components/ProButton";
import { useParams } from "react-router-dom";
import ReportsLayout from "../../../Components/ProForms/ProFormsLayout/ReportsFormsLayout";
import { getApi, postApi } from "../../../../utils/services";
import { ADD_RAP_SELECTION_PROCEDURE, GET_RAP_SELECTION_PROCEDURE_BYID } from "../../../../utils/services/apiPath";
import { errorToast, successToast } from "../../../../utils/toastHelper";

const SelectionFormConfig = {
  occuptionProcedure: {
    label: "Occuption Procedure",
    perKey: "occuptionProcedure",
    fieldProps: { multiline: true, rows: 4 },
    rules: { required: "Occuption Procedure is required!" }
  }
};
export default function SelectionProcedures() {
  const { id } = useParams();
  const [formConfig, setFormConfig] = useState(prepareInitialConfig(SelectionFormConfig));
  const { handleSubmit, control, setValue } = useForm({ defaultValues: prepareDefaultValues(SelectionFormConfig) });
  const defaultFormProps = { control };
  const [status, setStatus] = useState(STAUTES.IDLE);
  const isAddMode = useMemo(() => id === "add", [id]);
  const [isSaving, setIsSaving] = useState(false);

  const onSave = async formData => {
    if (isSaving) return;
    setIsSaving(true);
    setStatus(STAUTES.LOADING);
    const payload = {
      rapInfoGuid: id,
      occupationProcedures: formData?.occuptionProcedure
    };
    const { error } = await postApi(ADD_RAP_SELECTION_PROCEDURE, payload);
    if (error) return errorToast(error);
    getAllSelectionProcedure();
    setIsSaving(false);
    successToast(`Selection Procedure updated successfully.`);
    setStatus(STAUTES.IDLE);
  };

  const isEditModeOn = useMemo(() => Object.values(formConfig).some(({ mode }) => mode === "edit"), [formConfig]);
  const onEdit = () => setFormConfig(changeMode("edit"));

  const getAllSelectionProcedure = async () => {
    const { error, ...rest } = await getApi(`${GET_RAP_SELECTION_PROCEDURE_BYID}${id}`);
    if (error) return errorToast(error);
    setValue("occuptionProcedure", rest?.occupationProcedures);
  };

  useEffect(() => {
    if (!isAddMode) onEdit();
    getAllSelectionProcedure();
  }, [id]);
  return (
    <ReportsLayout>
      <Grid container sx={{ mt: 1 }} beingEdited={isEditModeOn} onEdit={onEdit} status={status}>
        <ProTextInput {...defaultFormProps} {...formConfig.occuptionProcedure} permission={fieldTruePermission} size={12} />
        <ProButton variant="contained" sx={{ mt: 2 }} onClick={handleSubmit(onSave)} disabled={isSaving}>
          {isSaving ? (
            <>
              <CircularProgress size={24} />
              <span style={{ marginLeft: 10 }}>Save</span>
            </>
          ) : (
            "Save"
          )}
        </ProButton>
      </Grid>
    </ReportsLayout>
  );
}
