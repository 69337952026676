import { IconButton, Box, Tooltip } from "@mui/material";
import React from "react";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import PropTypes from "prop-types";

export default function DownloadCell({ params, onOpen }) {
  return (
      <Box>
        <Tooltip title={"Download"} arrow>
          <IconButton onClick={() => onOpen(params.value)} color="primary">
            <CloudDownloadIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
  );
}


DownloadCell.propTypes = {
  params: PropTypes.object.isRequired,
  onOpen: PropTypes.func.isRequired,
};