import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FormSectionWrapper from "../../Components/ProForms/FormSectionWrapper";
import SettingFormsLayout from "../../Components/ProForms/ProFormsLayout/SettingFormsLayout";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import ProTextInput from "../../Components/Inputs/TextField";
import ProSelectField from "../../Components/Inputs/SelectField";
import ProCheckboxField from "../../Components/Inputs/CheckboxField";
import Layout from "../../Components/Layout";
import ProTextEditor from "../../Components/Inputs/TextEditor";
import { changeMode, changeModeForField, prepareDefaultValues, prepareInitialConfig } from "../../../utils/formHelper";
import { successToast } from "../../../utils/toastHelper";
import FileUploadField from "../../Components/Inputs/FileUploadField";

const CommunicationFormConfig = {
  client: {
    label: "Client",
    perKey: "Client",
    rules: { required: "Select Client!" }
  },
  communicationType: {
    label: "Type",
    perKey: "type",
    defaultValue: "Email"
  },
  entityType: {
    label: "Entity",
    perKey: "entityType",
    rules: { required: "Entity is Required!" }
  },
  subject: {
    label: "Subject",
    perKey: "subject"
  },
  emailBody: {
    label: "Email Body",
    perKey: "emailBody"
  },
  attachment: {
    label: "Attachment",
    perKey: "attachment",
    defaultValue: null
  },
  message: {
    label: "Message",
    fieldProps: { multiline: true, rows: 4 },
    perKey: "message"
  },
  isApprentice: {
    label: "Is Apprentice",
    perKey: "isApprentice"
  },
  partnerContacts: {
    label: "Partner",
    perKey: "partnerContacts"
  }
};

export default function CommunicationForm() {
  const navigate = useNavigate();
  const { id } = useParams();
  const isAddMode = useMemo(() => id === "add", [id]);
  const [formConfig, setFormConfig] = useState(prepareInitialConfig(CommunicationFormConfig));
  const { handleSubmit, control, watch } = useForm({ defaultValues: prepareDefaultValues(CommunicationFormConfig) });
  const communicationType = watch("communicationType");
  const entityType = watch("entityType");
  const isEditModeOn = useMemo(() => Object.values(formConfig).some(({ mode }) => mode === "edit"), [formConfig]);

  const backAction = () => navigate("/communication");
  const onEdit = () => setFormConfig(changeMode("edit"));
  const onCancel = () => (isAddMode ? backAction() : setFormConfig(changeMode()));

  const handleReadValueclick = name => setFormConfig(changeModeForField(name, "edit"));
  const typeOptions = [
    { label: "SMS", value: "SMS" },
    { label: "Email", value: "Email" }
  ];
  const entityOptions = [
    { label: "Employees", value: "Employees" },
    { label: "Partner Contacts", value: "Partner Contacts" }
  ];
  const onSubmit = async values => {
    successToast(`Email Sent Successfully`);
    backAction();
  };

 

  useEffect(() => {
    if (!isAddMode) onEdit();
  }, [id]);

  const defaultFormProps = { control, formValues: {}, isAddMode, handleReadValueclick };

  return (
    <Layout>
      <SettingFormsLayout
        hideBackbutton={true}
        title={"Communication"}
        beingEdited={isEditModeOn}
        isAddMode={true}
        hideEdit={true}
        onEdit={onEdit}
        onCancel={onCancel}
        onSave={handleSubmit(onSubmit)}
        buttonLabel={"Send"}
      >
        <FormSectionWrapper title={"Enter Details"}>
          <ProSelectField {...defaultFormProps} {...formConfig.client} />
          <ProSelectField {...defaultFormProps} {...formConfig.communicationType} options={typeOptions} />
          <ProSelectField {...defaultFormProps} {...formConfig.entityType} options={entityOptions} />
          {entityType == "Employees" && <ProCheckboxField {...defaultFormProps} {...formConfig.isApprentice} />}
          {entityType == "Partner Contacts" && <ProSelectField {...defaultFormProps} {...formConfig.partnerContacts} />}
        </FormSectionWrapper>
        {communicationType == "Email" && (
          <FormSectionWrapper title={"Enter Email Details"}>
            <ProTextInput {...defaultFormProps} {...formConfig.subject} size={12} />
            <ProTextEditor {...defaultFormProps} {...formConfig.emailBody} size={12} />
            <FileUploadField {...defaultFormProps} {...formConfig.attachment} />
          </FormSectionWrapper>
        )}
        {communicationType == "SMS" && (
          <>
            <FormSectionWrapper title={"Enter SMS Details"}>
              <ProTextInput {...defaultFormProps} {...formConfig.message} size={12} multiline rows={5} rowsMax={10} />
            </FormSectionWrapper>
            <Typography>0 characters used | 1 sms per recipient</Typography>
          </>
        )}
      </SettingFormsLayout>
    </Layout>
  );
}
