import { headerMenus } from "../../../utils/MenusConfig";
import { decryptText, encryptText } from "../../../utils/crypto";

export const setPermissionToLocalStorage = permissions => {
  const encryptedPermission = encryptText(JSON.stringify(permissions));
  localStorage.setItem("permissions", encryptedPermission);
};

export const getPermissionFromLocalStorage = () => {
  const permission = localStorage.getItem("permissions");
  return permission ? decryptText(permission) : {};
};

export const addReferenceToPermission = permission => {
  const permissionObject = {};
  permission.forEach(item => {
    const moduleReference = item.moduleName.toUpperCase().split(" ").join("_");
    const subModulePermissionsObject = {};
    item.subModulePermissions.forEach(subItem => {
      const subModuleReference = `${moduleReference}_${subItem.subModuleName.toUpperCase().split(" ").join("_")}`;
      subModulePermissionsObject[subModuleReference] = {
        ...subItem,
        reference: subModuleReference
      };
    });
    permissionObject[moduleReference] = {
      ...item,
      reference: moduleReference,
      subModulePermissions: subModulePermissionsObject
    };
  });
  return permissionObject;
};

export const headerPermissionTransformer = permissions => {
  const accesibleMenu = headerMenus
    .map(item => {
      const itemPermission = permissions[item.reference];
      return {
        ...item,
        isAccessible: itemPermission?.canView || item.ignorePermission,
        subMenus: item.subMenus.filter(subItem => subItem.ignorePermission || itemPermission?.subModulePermissions?.[subItem.reference]?.canView)
      };
    })
    .filter(item => item.isAccessible);
  return accesibleMenu;
};
