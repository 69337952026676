import { useCallback, useState } from "react";
import { STAUTES } from "../constant";

const usePageState = (defaultState = {}) => {
  const [pageState, setPageState] = useState({
    data: [],
    currentPage: 0,
    pageSize: 10,
    rowCount: 0,
    status: STAUTES.IDLE,
    sortModel: {},
    searchString: "",
    ...defaultState
  });

  const setStatus = useCallback(status => {
    setPageState(prevPageState => ({ ...prevPageState, status }));
  }, []);

  const setPagination = useCallback(({ page, pageSize }) => {
    setPageState(prevPageState => ({ ...prevPageState, currentPage: page, pageSize }));
  }, []);

  const setSortModel = useCallback(gridSortModel => {
    const selectedSort = gridSortModel[0];
    if (!selectedSort) return setPageState(prevPageState => ({ ...prevPageState, sortModel: {} }));
    const sortModel = {
      orderCol: selectedSort.field,
      orderByAscending: selectedSort.sort === "asc"
    };
    setPageState(prevPageState => ({ ...prevPageState, sortModel }));
  }, []);

  const setSearchString = useCallback(search => {
    const searchString = search.trim() === "" ? "" : search.replace(/\s{2,}/g, " ");
    setPageState(prevPageState => ({ ...prevPageState, searchString }));
  }, []);

  return {
    ...pageState,
    setStatus,
    setPageState,
    setPagination,
    setSortModel,
    setSearchString
  };
};

export default usePageState;
