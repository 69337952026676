import React, { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import Login from "../Pages/Login/Login";
import ClientList from "../Pages/clients/ClientList";
import ClientDetail from "../Pages/clients/clientDetail";
import JobTitles from "../Pages/JobTitles/JobTitles";
import AddJobTitles from "../Pages/JobTitles/AddJobTitles";
import AssignRapInfo from "../Pages/AssignRapInfo/AssignRapInfo";
import AddAssignRapInfo from "../Pages/AssignRapInfo/AddAssignRapInfo";
import RapList from "../Pages/Rap/RapList";
import RapDetail from "../Pages/Rap/RapDetail";
import ApprenticeManagement from "../Pages/ApprenticeManagement/ApprenticeList";
import ApprenticeDetail from "../Pages/ApprenticeManagement/ApprenticeDetail/ApprenticeDetail";
import SponsorsInfoList from "../Pages/SponsorsInfo/SponsorsInfoList";
import SponsorInfoForm from "../Pages/SponsorsInfo/SponsorInfoForm";
import AdminUserForm from "../Pages/settings/AdminUser/AdminUserForm";
import AdminUserList from "../Pages/settings/AdminUser/AdminUserList";
import CommunicationForm from "../Pages/Communication";
import MentorApprenticePairing from "../Pages/MentorApprenticePairing";
import ProtectedRoute from "./ProtectedRoute";
import ValidateOtp from "../Pages/ValidateOtp/ValidateOtp";
import ResetPassword from "../Pages/ResetPassword/ResetPassword";
import ForgotPassword from "../Pages/ForgotPassword/ForgotPassword";

const ClientInvoice = lazy(() => import("../Pages/ClientInvoice"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />
  },
  {
    path: "/resetpassword-otp",
    element: <ValidateOtp />
  },
  {
    path: "/reset-password",
    element: <ResetPassword />
  },
  {
    path: "/clients",
    element: <ProtectedRoute Component={ClientList} />
  },
  {
    path: "/clients/:id",
    element: <ProtectedRoute Component={ClientDetail} />
  },
  {
    path: "/clients/:id/:tab",
    element: <ProtectedRoute Component={ClientDetail} />
  },
  {
    path: "/job-titles",
    element: <ProtectedRoute Component={JobTitles} />
  },
  {
    path: "/job-titles/:id",
    element: <ProtectedRoute Component={AddJobTitles} />
  },
  {
    path: "/assign-rapInfo",
    element: <ProtectedRoute Component={AssignRapInfo} />
  },
  {
    path: "/assign-rapInfo/:id",
    element: <ProtectedRoute Component={AddAssignRapInfo} />
  },
  {
    path: "rap-info",
    element: <ProtectedRoute Component={RapList} />
  },
  {
    path: "rap-info/:id",
    element: <ProtectedRoute Component={RapDetail} />
  },
  {
    path: "rap-info/:id/:tab",
    element: <ProtectedRoute Component={RapDetail} />
  },
  {
    path: "/sponsor-info",
    element: <ProtectedRoute Component={SponsorsInfoList} />
  },
  {
    path: "/sponsor-info/:id",
    element: <ProtectedRoute Component={SponsorInfoForm} />
  },
  {
    path: "/sponsor-info/:id/:tab",
    element: <ProtectedRoute Component={SponsorInfoForm} />
  },
  {
    path: "/users",
    element: <ProtectedRoute Component={AdminUserList} />
  },
  {
    path: "/users/:id",
    element: <ProtectedRoute Component={AdminUserForm} />
  },
  {
    path: "/users/:id/:tab",
    element: <ProtectedRoute Component={AdminUserForm} />
  },
  {
    path: "/apprentice-management",
    element: <ProtectedRoute Component={ApprenticeManagement} />
  },
  {
    path: "/apprentice-management/:id",
    element: <ProtectedRoute Component={ApprenticeDetail} />
  },
  {
    path: "/apprentice-management/:id/:tab",
    element: <ProtectedRoute Component={ApprenticeDetail} />
  },
  {
    path: "/communication",
    element: <ProtectedRoute Component={CommunicationForm} />
  },
  {
    path: "/mentor-apprentice-pairing",
    element: <ProtectedRoute Component={MentorApprenticePairing} />
  },
  {
    path: "client-invoice",
    element: <ClientInvoice />
  }
]);

export default router;
