import { Button } from "@mui/material";
import React from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import "./NameCell.scss";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

export default function NameCell({ params, onClick, getRedirectUrl, showIcon = false }) {
  const { value, row } = params;
  const navigate = useNavigate();

  const navigateToDetail = () => {
    if (getRedirectUrl) {
      const redirectLink = getRedirectUrl(params);
      return navigate(redirectLink);
    }
    if (row.nameLink) navigate(row.nameLink);
  };

  const handleClick = () => {
    if (onClick) onClick(params);
    else navigateToDetail();
  };

  return (
    <Button className="proGrid-name" variant="text" color="primary" onClick={handleClick} startIcon={showIcon ? <AccountCircleIcon /> : null}>
      {value}
    </Button>
  );
}

NameCell.propTypes = {
  params: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  getRedirectUrl: PropTypes.func.isRequired,
  showIcon: PropTypes.bool,
};
