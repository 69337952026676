import React, { useEffect, useMemo, useState, useCallback } from "react";
import ProTextInput from "../../Components/Inputs/TextField";
import { changeMode, changeModeForField, checkEditEnabled, fieldTruePermission, prepareDefaultValues, prepareInitialConfig } from "../../../utils/formHelper";
import { useForm } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";
import Layout from "../../Components/Layout";
import SettingFormsLayout from "../../Components/ProForms/ProFormsLayout/SettingFormsLayout";
import { STAUTES } from "../../../utils/constant";
import { Grid } from "@mui/material";
import { ADD_RAP_JOB_TITLE, UPDATE_RAP_JOB_TITLE, GET_RAP_JOB_TITLE } from "../../../utils/services/apiPath";
import { getApi, postApi, putApi } from "../../../utils/services";
import { errorToast, successToast } from "../../../utils/toastHelper";

const JobProfileFormConfing = {
  jobTitle: {
    label: "Job Title",
    rules: { required: "Job Title is Required!" }
  },
  oneTCode: {
    label: "ONET Code",
    rules: { required: "ONET Code is Required!" }
  }
};

export default function AddJobTitles() {
  const { id } = useParams();
  const navigate = useNavigate();
  const isAddMode = useMemo(() => id === "add", [id]);
  const [status, setStatus] = useState(STAUTES.IDLE);
  const [formConfig, setFormConfig] = useState(prepareInitialConfig(JobProfileFormConfing, isAddMode ? "edit" : "read"));
  const { handleSubmit, control, setValue } = useForm({ defaultValues: prepareDefaultValues(JobProfileFormConfing) });
  const [jobTitleData, setJobTitleData] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  const isEditModeOn = useMemo(() => checkEditEnabled(isAddMode, formConfig), [isAddMode, formConfig]);

  const backAction = () => navigate("/job-titles");
  const onEdit = () => setFormConfig(changeMode("edit"));
  const onCancel = () => {
    if (isAddMode) backAction();
    else setFormConfig(changeMode());
  };

  const getDetailById = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const { error, ...data } = await getApi(`${GET_RAP_JOB_TITLE}/${id}`);
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    setJobTitleData(data);
    Object.keys(JobProfileFormConfing).forEach(field => setValue(field, data[field]));
  }, [setValue, id]);

  const handleReadValueclick = name => setFormConfig(changeModeForField(name, "edit"));


  const onSubmit = async data => {
    if (isSaving) return; 
    setIsSaving(true);
    setStatus(STAUTES.LOADING);
    const payload = { ...jobTitleData, ...data };
    const method = isAddMode ? postApi : putApi;
    const { error } = await method(isAddMode ? ADD_RAP_JOB_TITLE : UPDATE_RAP_JOB_TITLE, payload);
    setIsSaving(false); 
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    successToast(`Job Title ${isAddMode ? "added" : "updated"} successfully.`);
    backAction();
  };

  useEffect(() => {
    if (!isAddMode) getDetailById(id);
  }, [id, isAddMode, getDetailById]);

  const defaultFormProps = { control, formValues: jobTitleData, isAddMode, handleReadValueclick };

  return (
    <Layout status={status}>
      <SettingFormsLayout
        backAction={backAction}
        title={`${isAddMode ? "Add" : "Edit"} Job Title`}
        backLabel="Go Back to Job Titles"
        beingEdited={isEditModeOn}
        onEdit={onEdit}
        onCancel={onCancel}
        onSave={handleSubmit(onSubmit)}
        isSaving={isSaving}
      >
        <Grid container spacing={2}>
          <ProTextInput {...defaultFormProps} {...formConfig.jobTitle} permission={fieldTruePermission}/>
          <ProTextInput {...defaultFormProps} {...formConfig.oneTCode} permission={fieldTruePermission}/>
        </Grid>
      </SettingFormsLayout>
    </Layout>
  );
}
