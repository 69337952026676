import React from "react";
import PropTypes from "prop-types";

export default function UrlIcon({ fill = "#00050B" }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 14 14">
      <g fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round">
        <path d="M7 13.5a6.5 6.5 0 1 0 0-13a6.5 6.5 0 0 0 0 13M.5 7h13"></path>
        <path d="M9.5 7A11.22 11.22 0 0 1 7 13.5A11.22 11.22 0 0 1 4.5 7A11.22 11.22 0 0 1 7 .5A11.22 11.22 0 0 1 9.5 7"></path>
      </g>
    </svg>
  );
}

UrlIcon.propTypes = {
  fill: PropTypes.string
};
