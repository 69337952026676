import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ProGrid from "../../Components/ProGrid/v2";
import NameCell from "../../Components/ProGrid/components/NameCell";
import DeleteCell from "../../Components/ProGrid/components/DeleteCell";
import Layout from "../../Components/Layout";
import { STAUTES } from "../../../utils/constant";
import { errorToast, successToast } from "../../../utils/toastHelper";
import usePageState from "../../../utils/customHooks/usePageState";
import useColumn from "../../../utils/customHooks/useColumns";
import WebLinkCell from "../../Components/ProGrid/components/WebLinkCell";
import { DELETE_CLIENT, GET_CLIENTS } from "../../../utils/services/apiPath";
import { deleteApi, postApi } from "../../../utils/services";

const getRedirectUrl = params => {
  return `/clients/${params.id}`;
};

export default function ClientList() {
  const {
    currentPage,
    pageSize,
    data: clientsData,
    rowCount,
    status,
    sortModel,
    searchString,
    setPageState,
    setPagination,
    setStatus,
    setSortModel,
    setSearchString
  } = usePageState();
  const navigation = useNavigate();
  const columns = useColumn(
    [
      {
        field: "companyName",
        headerName: "Company Name",
        renderCell: params => <NameCell params={params} getRedirectUrl={getRedirectUrl} />
      },
      {
        field: "title",
        headerName: "Title"
      },
      {
        field: "name",
        headerName: "Name",
        valueGetter: params => `${params.row.contactFirstName || ""} ${params.row.contactLastName || ""}`
      },
      {
        field: "city",
        headerName: "City"
      },
      {
        field: "state",
        headerName: "State"
      },
      {
        field: "zip",
        headerName: "Zip"
      },
      {
        field: "website",
        headerName: "Website",
        renderCell: params => <WebLinkCell params={params} />
      },
      {
        field: "clientGuid",
        headerName: "Actions",
        isDeleteColumn: true,
        disableExport: true,
        width: 80,
        renderCell: params => <DeleteCell params={params} onDelete={onDelete} title={"Delete Client"} />
      }
    ],
    true
  );

  const onDelete = async id => {
    const { error } = await deleteApi(DELETE_CLIENT, { params: { clientId: id } });
    if (error) return errorToast(error);
    successToast("Client deleted successfully.");
    getClientList();
  };

  const addButtonAction = () => {
    navigation("/clients/add");
  };

  const getClientList = useCallback(async () => {
    setStatus(STAUTES.LOADING);
    const payload = {
      pageIndex: searchString ? 1 : currentPage + 1,
      pageSize: pageSize,
      searchString: searchString,
      orderByAscending: true,
      orderCol: "companyName",
      ...sortModel
    };
    const { data, error, totalRecords } = await postApi(GET_CLIENTS, payload);
    setStatus(STAUTES.IDLE);
    if (error) return errorToast(error);
    setPageState(prevPageInfo => ({ ...prevPageInfo, data: data || [], rowCount: totalRecords }));
  }, [currentPage, pageSize, sortModel, searchString, setPageState, setStatus]);

  useEffect(() => {
    getClientList();
  }, [getClientList]);

  return (
    <Layout canView={true}>
      <ProGrid
        title="Clients"
        loading={status === STAUTES.LOADING}
        options={{
          getRowId: row => row.clientGuid,
          rowCount: rowCount,
          paginationMode: "server",
          paginationModel: { pageSize: pageSize, page: currentPage },
          onPaginationModelChange: setPagination,
          sortingMode: "server",
          onSortModelChange: setSortModel
        }}
        columns={columns}
        hideAddButton={false}
        hideExport={false}
        rows={clientsData}
        searchMode={!!searchString}
        searchModel={{ defaultValue: searchString, handleDebounce: setSearchString }}
        addButtonLabel="+ Add Client"
        addButtonAction={addButtonAction}
      />
    </Layout>
  );
}
